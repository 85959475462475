import { Action, ActionwithMultipart } from "../config/action";

export const sendOtpCall = async (payload) => {
  const data = await Action.post("/forgetpassword", payload);
  return data.data;
};
export const verifyOtpCall = async (payload) => {
  const data = await Action.post("/forgetpassword/verify", payload);
  return data.data;
};
export const resetPaswordCall = async (payload) => {
  const data = await Action.post("/resetpassword", payload);
  return data.data;
};
export const emptyLocalStorageOnLogout = async (navigate) => {
  localStorage.removeItem("isLogin");
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  // console.log("Ye chala...");

  navigate("/");
};
