import React, { Component } from "react";
import Avatar from "./Avatar";

export default function ChatListItems(props) {
  const selectChat = (e) => {
    for (
      let index = 0;
      index < e.currentTarget.parentNode.children.length;
      index++
    ) {
      e.currentTarget.parentNode.children[index].classList.remove("active");
    }
    e.currentTarget.classList.add("active");
    props.setRoomIdFunc(props.chatRoomId);
  };

  return (
    <div
      key={props.index}
      style={{ animationDelay: `0.${props.animationDelay}s` }}
      onClick={selectChat}
      className={`chatlist__item ${props.active ? props.active : ""} `}
    >
      <Avatar image={props.image} isOnline={props.isOnline} />

      <div className="userMeta" key={props.index}>
        <p>{props.name}</p>
        <span className="activeTime">{props.lastUpdateTime}</span>
      </div>
    </div>
  );
}
