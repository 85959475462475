import React from "react";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Dropdown,
  Modal,
  Table,
  Card,
  Form,
  Toast,
} from "react-bootstrap";
import edit from "../assets/images/Edit.png";
import deleteicon from "../assets/images/delete.png";
import Sidebar from "../component/sidebar";
import Header from "../component/header";

import { getgrades, addgrade, updategrade } from "../Services/Grades.service";
import { Navigate, useNavigate, useParams } from "react-router-dom";
// import { findAllInRenderedTree } from "react-dom/test-utils";
import { toast } from "react-toastify";
import { imageURL } from "../config/config";
import { emptyLocalStorageOnLogout } from "../Services/Auth.service";
// import { Action } from "../config/action";

export default function Grades() {
  const params = useParams();

  const [id, setid] = useState("");
  const [showndeletemodal, setshowndeletemodal] = useState(false);
  const [showncreatemodal, setshowncreatemodal] = useState(false);
  const [shownupdatemodal, setshownupdatemodal] = useState(false);

  const [payload, setpayload] = useState({
    name: "",
  });
  const reset = () => {
    setid("");
    setpayload({
      name: "",
    });
  };

  const [count, setcount] = useState([]);
  const [page, setpage] = useState(1);

  const [gradedata, setgradedata] = useState([]);
  const [gradecount, setgradecount] = useState(0);
  const getdata = async () => {
    try {
      const data = await getgrades();

      if (data.status == true) {
        const N =
          data.data.length % 10 == 0
            ? parseInt(data?.data?.length / 10)
            : parseInt(data?.data.length / 10) + 1;

        setcount(Array.from({ length: N }, (_, index) => index + 1));
        setgradecount(data.data.length);
        setgradedata(data.data);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      if (error.response.status == 401) {
        emptyLocalStorageOnLogout(navigate);
      }
    }
  };

  // const getdatabyquery = async (query) => {
  //   const data = await getuserbyquery(query);

  //   if (data.status == 1) {
  //     const N =
  //       data?.count % 10 == 0
  //         ? parseInt(data?.count / 10)
  //         : parseInt(data?.count / 10) + 1;

  //     setcount(Array.from({ length: N }, (_, index) => index + 1));
  //     setusercount(data.count);
  //     setuser(data.data);
  //   } else {
  //     toast.error(data.message);
  //   }
  // };

  const updatedata = async () => {
    const response = await updategrade(id, { name: payload.name });

    if (response.status == true) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
    getdata();
    reset();
  };
  // const deletedata = async () => {
  //   const response = await deleteUser(id);
  //   if (response.status == 1) {
  //     toast.success(response.message);
  //   } else {
  //     toast.error(response.message);
  //   }

  //   getdata(page);
  //   reset();
  // };
  const insertdata = async () => {
    const data = await addgrade(payload);
    if (data.status == true) {
      toast.success(data.message);
      getdata();
    } else {
      toast.error(data.message);
    }

    // reset();
  };

  const [filterdata, setfilterdata] = useState([]);

  const [filter, setfilter] = useState({
    attribute: "Select any",
    value: "",
  });

  useEffect(() => {
    const values = Object.values(filter);

    if (values[1] != "") {
      setfilterdata(
        gradedata.filter((item) => {
          return item[filter.attribute] == filter.value;
        })
      );
    } else {
      setfilterdata([]);
    }
  }, [filter.value]);

  useEffect(() => {
    if (filterdata.length == 0) {
      const N =
        gradedata?.length % 10 == 0
          ? parseInt(gradedata?.length / 10)
          : parseInt(gradedata?.length / 10) + 1;
      setcount(Array.from({ length: N }, (_, index) => index + 1));
    } else {
      const N =
        filterdata?.length % 10 == 0
          ? parseInt(filterdata?.length / 10)
          : parseInt(filterdata?.length / 10) + 1;
      setcount(Array.from({ length: N }, (_, index) => index + 1));
    }
  }, [filterdata]);

  const islogin = JSON.parse(localStorage.getItem("isLogin"));
  const navigate = useNavigate();
  useEffect(() => {
    if (islogin != true) {
      navigate("/");
    }

    getdata();
  }, [page]);

  return (
    <>
      <Sidebar />
      <Header />
      <div className="wrapper">
        <Container fluid>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h1>Grades</h1>
            <Button
              onClick={() => {
                setshowncreatemodal(true);
              }}
              className="mb-2"
            >
              Add Grades
            </Button>
          </div>
          <Card>
            <Card.Body>
              <Row>
                <Col style={{ display: "flex" }}>
                  {/* <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      {filter?.attribute}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={(e) => {
                          setfilter({ ...filter, attribute: "name" });
                        }}
                      >
                        Name
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          setfilter({ ...filter, attribute: "description" });
                        }}
                      >
                        Description
                      </Dropdown.Item>{" "}
                    </Dropdown.Menu>
                  </Dropdown> */}
                  {/* <label>Search By Name</label>
                  <input
                    className="ml-3"
                    type="text"
                    placeholder={filter.attribute}
                    onChange={(e) => {
                      setfilter({
                        ...filter,
                        attribute: "name",
                        value: e.target.value,
                      });
                    }}
                  ></input> */}
                  {/* {filter.attribute == "name" ||
                  filter.attribute == "description" ? (
                  ) : null} */}
                </Col>
              </Row>

              <Table className="" responsive>
                <thead className="thead">
                  <tr>
                    <td>Name</td>
                    {/* <td>Description</td> */}
                    {/* <td>City Name</td> */}
                    {/* <td>Title </td>
                    <td>Status</td>
                    <td>Spotlight</td> */}

                    <td className="text-right">Action</td>
                  </tr>
                </thead>
                <tbody>
                  {filterdata.length > 0
                    ? filterdata
                        ?.slice((page - 1) * 10, (page - 1) * 10 + 10)
                        .map((item, index) => {
                          return (
                            <tr>
                              <td>{item?.name}</td>

                              <td className="text-right">
                                <div className="action">
                                  <i
                                    className="icon-edit"
                                    onClick={() => {
                                      setid(item.id);
                                      setpayload(item);
                                      setshownupdatemodal(true);
                                    }}
                                  ></i>

                                  {/* <i
                                className="icon-trash"
                                onClick={() => {
                                  setid(item.id);
  
                                  setshowndeletemodal(true);
                                }}
                              ></i> */}
                                </div>
                              </td>
                            </tr>
                          );
                        })
                    : gradedata
                        ?.slice((page - 1) * 10, (page - 1) * 10 + 10)

                        .map((item, index) => {
                          return (
                            <tr>
                              <td>{item?.name}</td>

                              <td className="text-right">
                                <div className="action">
                                  <i
                                    className="icon-edit"
                                    onClick={() => {
                                      setid(item.id);
                                      setpayload(item);
                                      setshownupdatemodal(true);
                                    }}
                                  ></i>

                                  {/* <i
                              className="icon-trash"
                              onClick={() => {
                                setid(item.id);

                                setshowndeletemodal(true);
                              }}
                            ></i> */}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                </tbody>
              </Table>
              <div className="menupagination">
                Showing {gradedata.length}- {gradecount} results
                <div className="pagination">
                  {page == 1 ? (
                    <button disable style={{ cursor: "default" }}>
                      Prev
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setpage(page - 1);
                      }}
                    >
                      Prev
                    </button>
                  )}

                  {count?.map((item, index) => {
                    return (
                      <button
                        className={item == page ? "active" : ""}
                        onClick={() => {
                          setpage(item);
                        }}
                      >
                        {item}
                      </button>
                    );
                  })}
                  {page == count?.length ? (
                    <button disable style={{ cursor: "default" }}>
                      Next1
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setpage(page + 1);
                      }}
                    >
                      Next2
                    </button>
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Container>
        {/* delete modal */}
        {/* <Modal
          show={showndeletemodal}
          onHide={() => {
            reset();

            setshowndeletemodal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete user</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center align-items-center">
              <Button
                variant="secondary"
                type="submit"
                className="mr-2"
                onClick={(e) => {
                  e.preventDefault();
                  setid("");

                  setshowndeletemodal(false);
                }}
              >
                No
              </Button>
              <Button
                variant="primary"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  deletedata();
                  setshowndeletemodal(false);
                }}
              >
                Yes
              </Button>
            </div>
          </Modal.Body>
        </Modal> */}

        <Modal
          show={showncreatemodal || shownupdatemodal}
          size="lg"
          onHide={() => {
            reset();

            setshowncreatemodal(false);
            setshownupdatemodal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {showncreatemodal ? "Add" : "Update"} Grade
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col className="mb-3">
                  {" "}
                  <label>Name</label>{" "}
                  <input
                    name="name"
                    type="text"
                    placeholder="Enter Grade"
                    value={payload.name}
                    onChange={(e) =>
                      setpayload({ ...payload, name: e.target.value })
                    }
                  />
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                setid("");
                reset();

                setshowncreatemodal(false);
                setshownupdatemodal(false);
              }}
            >
              Cancel
            </Button>
            {showncreatemodal ? (
              <Button
                variant="primary"
                type="submit"
                onClick={(e) => {
                  setshowncreatemodal(false);
                  setshownupdatemodal(false);
                  insertdata();
                }}
              >
                Create
              </Button>
            ) : (
              <Button
                variant="primary"
                type="submit"
                onClick={(e) => {
                  setshowncreatemodal(false);
                  setshownupdatemodal(false);
                  updatedata();
                }}
              >
                Update
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
