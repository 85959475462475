import React, { useState, createRef, useEffect } from "react";

import "./chatContent.css";
import Avatar from "../chatList/Avatar";
import ChatItem from "./ChatItem";
import { imageURL } from "../../../config/config";
import { Oval } from "react-loader-spinner";

export default function ChatContent({
  roomMessages,
  adminId,
  roomId,
  socketInstance,
  chatLoading,
}) {
  const messagesEndRef = createRef(null);
  const [chatItems, setchatItms] = useState(null);
  const [msg, setMsg] = useState("");
  const chatItms = [
    {
      key: 1,
      image:
        "https://pbs.twimg.com/profile_images/1116431270697766912/-NfnQHvh_400x400.jpg",
      type: "",
      msg: "Hi Tim, How are you?",
    },
    {
      key: 2,
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTA78Na63ws7B7EAWYgTr9BxhX_Z8oLa1nvOA&usqp=CAU",
      type: "other",
      msg: "I am fine.",
    },
    {
      key: 3,
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTA78Na63ws7B7EAWYgTr9BxhX_Z8oLa1nvOA&usqp=CAU",
      type: "other",
      msg: "What about you?",
    },
    {
      key: 4,
      image:
        "https://pbs.twimg.com/profile_images/1116431270697766912/-NfnQHvh_400x400.jpg",
      type: "",
      msg: "Awesome these days.",
    },
    {
      key: 5,
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTA78Na63ws7B7EAWYgTr9BxhX_Z8oLa1nvOA&usqp=CAU",
      type: "other",
      msg: "Finally. What's the plan?",
    },
    {
      key: 6,
      image:
        "https://pbs.twimg.com/profile_images/1116431270697766912/-NfnQHvh_400x400.jpg",
      type: "",
      msg: "what plan mate?",
    },
    {
      key: 7,
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTA78Na63ws7B7EAWYgTr9BxhX_Z8oLa1nvOA&usqp=CAU",
      type: "other",
      msg: "I'm taliking about the tutorial",
    },
  ];

  useEffect(() => {
    console.log(roomMessages);
    setchatItms(roomMessages?.ChatRoom);
  }, [roomMessages]);

  useEffect(() => {
    setchatItms(null);
  }, [roomId]);

  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    window.addEventListener("keydown", (e) => {
      if (e.keyCode == 13) {
        if (msg != "") {
          chatItms.push({
            key: 1,
            type: "",
            msg: msg,
            image:
              "https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png",
          });
          this.setState({ chat: [...this.chatItms] });
          scrollToBottom();
          // this.setState({ msg: "" });
        }
      }
    });
    scrollToBottom();
  }, [chatItems]);

  const onStateChange = (e) => {
    setMsg(e.target.value);
  };
  const submitFunc = (e) => {
    e.preventDefault();
    console.log(msg);
    console.log(socketInstance.id);
    // send message
    socketInstance.emit("sendChatMessage", {
      body: {
        roomid: roomId,
        user: adminId,
        message: msg,
        lastSeen: true,
      },
    });
    setMsg("");

    // recieve message
    socketInstance.on("sendChatMessage", function (newData) {
      console.log("receive message", newData);
      setchatItms(newData?.ChatRoom);
      // if (newData.length) setAllChats(newData);
      // setAllPost((prev) => [newData, ...prev]);
    });
  };

  return (
    <div className="main__chatcontent">
      <div className="content__header">
        {chatItems != null ? (
          <div className="blocks">
            <div className="current-chatting-user">
              <Avatar
                isOnline="active"
                image={
                  roomMessages?.User1?.profilePicture
                    ? imageURL + roomMessages?.User1?.profilePicture
                    : "https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png"
                }
              />
              <p>
                {roomMessages?.User1?.fullName
                  ? roomMessages?.User1?.fullName
                  : "No Name"}
              </p>
            </div>
          </div>
        ) : (
          <p></p>
        )}
        <div className="blocks">
          <div className="settings">
            <button className="btn-nobg">
              <i className="fa fa-cog"></i>
            </button>
          </div>
        </div>
      </div>
      {chatItems != null ? (
        <>
          <div className="content__body">
            <div className="chat__items">
              {chatItems?.map((itm, index) => {
                return (
                  <ChatItem
                    animationDelay={index + 2}
                    time={itm.time}
                    key={index}
                    user={itm?.user?._id != adminId ? "other" : "me"}
                    msg={itm?.message}
                    image={
                      itm?.user?._id != adminId
                        ? roomMessages.User1?.profilePicture
                        : roomMessages.User2?.profilePicture
                      // : null
                    }
                  />
                  // <ChatItem
                  //   animationDelay={index + 2}
                  //   key={itm.key}
                  //   user={itm.type ? itm.type : "me"}
                  //   msg={itm.msg}
                  //   image={itm.image}
                  // />
                );
              })}
              <div ref={messagesEndRef} />
            </div>
          </div>
          <div className="content__footer">
            <div className="">
              {/* <button className="addFiles">
              <i className="fa fa-plus"></i>
            </button> */}
              <form onSubmit={submitFunc} className="sendNewMessage">
                <input
                  type="text"
                  placeholder="Type a message here"
                  onChange={onStateChange}
                  value={msg}
                  required={true}
                />
                <button className="btnSendMsg" id="sendMsgBtn" type="submit">
                  <i className="icon-arrow-right"></i>
                </button>
              </form>
            </div>
          </div>
        </>
      ) : (
        <h4 className="no-chat-text">
          {chatLoading ? (
            <span className="centerLoader">
              <Oval
                height={60}
                width={60}
                color="#c6d4ef"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#0a3481"
                strokeWidth={2}
                strokeWidthSecondary={2}
                // style={{ justifyContent: "center" }}
              />
            </span>
          ) : (
            "Welcome to Chat"
          )}
        </h4>
      )}
    </div>
  );
}
