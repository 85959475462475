import { Action, ActionwithMultipart } from "../config/action";

// const OrderCollectionRef = collection(db, "Orders");
export const adminLogin = async (payload) => {
  const data = await Action.post("/login", payload);

  return data.data;
};

export const getvideos = async () => {
  const data = await Action.get(`/video/get`);

  return data.data;
};
export const getvideoCategory = async () => {
  const data = await Action.get(`/videocatagory/get`);

  return data.data;
};
export const getvideosById = async (id) => {
  const data = await Action.get(`/video/get/${id}`);

  return data.data;
};
export const addvideo = async (payload, videoFile, thumbnailFile) => {
  // console.log(payload);
  try {
    let formData = new FormData();
    formData.append("title", payload?.title);
    formData.append("description", payload?.description);
    formData.append("grade", payload?.grade);
    formData.append("category", payload?.category);
    if (videoFile) formData.append("video", videoFile);
    if (thumbnailFile) formData.append("thumbnail", thumbnailFile);
    // formData.append("video", payload?.video_url);
    // formData.append("thumbnail", payload?.thumbnail_url);

    const data = await ActionwithMultipart.post("/video/create", formData);
    return data.data;
  } catch (error) {
    return error;
  }
};
export const updatevideo = async (id, payload, videoFile, thumbnailFile) => {
  // console.log({ id, payload, videoFile, thumbnailFile });
  const data = Object.fromEntries(
    Object.entries(payload).filter(([_, v]) => (v != null) & (v != ""))
  );
  let formData = new FormData();
  formData.append("title", payload?.title);
  formData.append("description", payload?.description);
  formData.append("grade", payload?.grade);
  formData.append("category", payload?.category);
  if (videoFile) formData.append("video", videoFile);
  if (thumbnailFile) formData.append("thumbnail", thumbnailFile);
  // formData.append("thumbnail", payload?.thumbnail_url);

  const updateddata = await ActionwithMultipart.put(
    `/video/update/${id}`,
    data
  );
  return updateddata.data;
};
export const addvideoCat = async (payload, thumbnail) => {
  // console.log(payload, thumbnail);
  let formData = new FormData();
  if (thumbnail) formData.append("Thumbnail", thumbnail);
  formData.append("name", payload?.name);
  formData.append("description", payload?.description);
  const data = await ActionwithMultipart.post(
    "/videocatagory/create",
    formData
  );
  return data.data;
};
export const updatevideoCat = async (id, payload, thumbnail) => {
  delete payload.id;
  delete payload.thumbnailPath;
  // console.log({ id, payload, thumbnail });
  // console.log(thumbnail);
  let formData = new FormData();
  if (thumbnail) formData.append("Thumbnail", thumbnail);
  formData.append("name", payload?.name);
  formData.append("description", payload?.description);

  const updateddata = await ActionwithMultipart.put(
    `/videocatagory/update/${id}`,
    formData
  );
  return updateddata.data;
};
