import React from "react";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Dropdown,
  Modal,
  Table,
  Card,
  Form,
  Toast,
} from "react-bootstrap";
import edit from "../assets/images/Edit.png";
import deleteicon from "../assets/images/delete.png";
import Sidebar from "../component/sidebar";
import Header from "../component/header";

import {
  getvideos,
  addvideo,
  updatevideo,
  getvideosById,
  getvideoCategory,
  updatevideoCat,
  addvideoCat,
} from "../Services/Video.service";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";
import { imageURL } from "../config/config";
import { Action } from "../config/action";
import { emptyLocalStorageOnLogout } from "../Services/Auth.service";

export default function VideoCategory() {
  const params = useParams();

  const [id, setid] = useState("");
  const [showndeletemodal, setshowndeletemodal] = useState(false);
  const [showncreatemodal, setshowncreatemodal] = useState(false);
  const [shownupdatemodal, setshownupdatemodal] = useState(false);

  const [payload, setpayload] = useState({
    name: "",
    description: "",
    // thumbnailPath: "",
    // grade: 0,
  });
  const [image, setimage] = useState({});
  const [thumbnail, setthumbnail] = useState(null);

  const reset = () => {
    setid("");
    setpayload({});
    setthumbnail(null);
  };

  const [count, setcount] = useState([]);
  const [page, setpage] = useState(1);

  const [video, setvideo] = useState([]);
  const [videocount, setvideocount] = useState(0);
  const getdata = async () => {
    try {
      const data = await getvideoCategory();
      // console.log(data);

      if (data.status == true) {
        const N =
          data?.data.length % 10 == 0
            ? parseInt(data?.data.length / 10)
            : parseInt(data?.data.length / 10) + 1;

        setcount(Array.from({ length: N }, (_, index) => index + 1));
        setvideocount(data.data.length);
        setvideo(data.data);
      } else {
        toast.error(data.message);
      }
      // console.log(video);
    } catch (error) {
      if (error.response.status == 401) {
        emptyLocalStorageOnLogout(navigate);
      }
    }
  };

  // const getdatabyquery = async (query) => {
  //   const data = await getuserbyquery(query);

  //   if (data.status == 1) {
  //     const N =
  //       data?.count % 10 == 0
  //         ? parseInt(data?.count / 10)
  //         : parseInt(data?.count / 10) + 1;

  //     setcount(Array.from({ length: N }, (_, index) => index + 1));
  //     setusercount(data.count);
  //     setuser(data.data);
  //   } else {
  //     toast.error(data.message);
  //   }
  // };

  const updatedata = async () => {
    const response = await updatevideoCat(payload.id, payload, thumbnail);
    // setthumbnail(null);

    if (response.status == true) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
    getdata();
    reset();
  };
  // const deletedata = async () => {
  //   const response = await deleteUser(id);
  //   if (response.status == 1) {
  //     toast.success(response.message);
  //   } else {
  //     toast.error(response.message);
  //   }

  //   getdata();
  //   reset();
  // };
  const insertdata = async () => {
    const data = await addvideoCat(payload, thumbnail);
    // setthumbnail(null);
    // // console.log(data);
    if (data.status == true) {
      toast.success(data.message);
      getdata(page);
    } else {
      toast.error(data.message);
    }

    reset();
  };

  const [filterdata, setfilterdata] = useState([]);

  const [filter, setfilter] = useState({
    attribute: "Select any",
    value: "",
  });

  useEffect(() => {
    const values = Object.values(filter);
    if (values[1] != "") {
      setfilterdata(
        video.filter((item) => {
          return item[filter.attribute] == filter.value;
        })
      );
    } else {
      setfilterdata([]);
    }
  }, [filter.value]);

  useEffect(() => {
    if (filterdata.length == 0) {
      const N =
        video?.length % 10 == 0
          ? parseInt(video?.length / 10)
          : parseInt(video?.length / 10) + 1;
      setcount(Array.from({ length: N }, (_, index) => index + 1));
    } else {
      const N =
        filterdata?.length % 10 == 0
          ? parseInt(filterdata?.length / 10)
          : parseInt(filterdata?.length / 10) + 1;
      setcount(Array.from({ length: N }, (_, index) => index + 1));
    }
  }, [filterdata]);

  const islogin = JSON.parse(localStorage.getItem("isLogin"));
  const navigate = useNavigate();
  useEffect(() => {
    if (islogin != true) {
      navigate("/");
    }

    getdata();
  }, [page]);
  return (
    <>
      <Sidebar />
      <Header />
      <div className="wrapper">
        <Container fluid>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h1>Video Cateogory</h1>
            <Button
              className="mb-2"
              onClick={() => {
                setshowncreatemodal(true);
              }}
            >
              Add Cateogory
            </Button>
          </div>
          <Card>
            <Card.Body>
              <Row>
                <Col style={{ display: "flex" }}>
                  {/* <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      {filter?.attribute}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={(e) => {
                          setfilter({ ...filter, attribute: "first_name" });
                        }}
                      >
                        Full Name
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          setfilter({ ...filter, attribute: "user_email" });
                        }}
                      >
                        Email
                      </Dropdown.Item>{" "}
                      <Dropdown.Item
                        onClick={(e) => {
                          setfilter({
                            ...filter,
                            attribute: "user_is_verified",
                          });
                        }}
                      >
                        Is Verified
                      </Dropdown.Item>{" "}
                    </Dropdown.Menu>
                  </Dropdown> */}
                  {/* {filter.attribute == "user_is_verified" ? (
                    <Dropdown>
                      <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        user is verified
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          value={0}
                          onClick={(e) => {
                            setfilter({ ...filter, value: 0 });
                          }}
                        >
                          UnVerified
                        </Dropdown.Item>
                        <Dropdown.Item
                          value={1}
                          onClick={(e) => {
                            setfilter({ ...filter, value: 1 });
                          }}
                        >
                          Verified
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <input
                      className="ml-3"
                      type="text"
                      placeholder={filter.attribute}
                      onChange={(e) => {
                        setfilter({ ...filter, value: e.target.value });
                      }}
                    ></input>
                  )} */}
                </Col>
              </Row>

              <Table className="" responsive>
                <thead className="thead">
                  <tr>
                    <td>Name</td>
                    <td>Description</td>
                    {/* <td>City Name</td> */}
                    {/* <td>Title </td> */}
                    {/* <td>Status</td> */}
                    <td>Thumbnail</td>

                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                  {video?.map((item, index) => {
                    return (
                      <tr>
                        <td>
                          {
                            item?.name
                            // ? item.name
                            // : item?.first_name + item?.last_name
                          }
                        </td>
                        <td>
                          <p>{item?.description}</p>
                        </td>
                        <td>
                          <img
                            width="50"
                            height="50"
                            src={item?.thumbnailPath}
                          />
                          {/* <p>{item?.titles?.join(", ")}</p> */}
                        </td>
                        {/* <td>
                          {item?.user_is_verified == 1
                            ? "Verified"
                            : "UnVerified"}
                        </td> */}
                        {/* <td>{item?.spotlightperweek}</td> */}

                        <td>
                          <div className="action">
                            <i
                              className="icon-edit"
                              onClick={() => {
                                setid(item._id);
                                setpayload(item);
                                setshownupdatemodal(true);
                              }}
                            ></i>

                            {/* <i
                              className="icon-trash"
                              onClick={() => {
                                setid(item._id);

                                setshowndeletemodal(true);
                              }}
                            ></i> */}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="menupagination">
                Showing {video.length}- {videocount} results
                {/* <div className="pagination">
                  {page == 1 ? (
                    <button disable style={{ cursor: "default" }}>
                      Prev
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setpage(page - 1);
                      }}
                    >
                      Prev
                    </button>
                  )}

                  {count?.map((item, index) => {
                    return (
                      <button
                        className={item == page ? "active" : ""}
                        onClick={() => {
                          setpage(item);
                        }}
                      >
                        {item}
                      </button>
                    );
                  })}
                  {page == count?.length ? (
                    <button disable style={{ cursor: "default" }}>
                      Next
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setpage(page + 1);
                      }}
                    >
                      Next
                    </button>
                  )}
                </div> */}
              </div>
            </Card.Body>
          </Card>
        </Container>
        {/* delete modal
        <Modal
          show={showndeletemodal}
          onHide={() => {
            reset();

            setshowndeletemodal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Video</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center align-items-center">
              <Button
                variant="secondary"
                type="submit"
                className="mr-2"
                onClick={(e) => {
                  e.preventDefault();
                  setid("");

                  setshowndeletemodal(false);
                }}
              >
                No
              </Button>
              <Button
                variant="primary"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  deletedata();
                  setshowndeletemodal(false);
                }}
              >
                Yes
              </Button>
            </div>
          </Modal.Body>
        </Modal> */}

        <Modal
          show={showncreatemodal || shownupdatemodal}
          size="lg"
          onHide={() => {
            reset();

            setshowncreatemodal(false);
            setshownupdatemodal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {showncreatemodal ? "Add" : "Update"} Video Category
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col className="mb-3">
                  {" "}
                  <label>Name</label>{" "}
                  <input
                    name="name"
                    type="text"
                    placeholder="Name"
                    value={payload.name}
                    onChange={(e) =>
                      setpayload({ ...payload, name: e.target.value })
                    }
                  />
                </Col>

                <Col className="mb-3">
                  <label>Description</label>
                  <input
                    name="description"
                    type="text"
                    placeholder="type new description here"
                    value={payload.description}
                    onChange={(e) =>
                      setpayload({ ...payload, description: e.target.value })
                    }
                  />
                </Col>
              </Row>

              <Row>
                <Col className="mb-3">
                  <Form.Group
                    //  controlId="formFile"
                    className="mb-3"
                  >
                    <Form.Label>Thumbnail</Form.Label>
                    <Form.Control
                      type="file"
                      //  onChange={()=>}
                      onChange={(e) => setthumbnail(e.target.files[0])}
                      accept="image/*"
                      name="fileInput"
                      id="fileInput"
                    />
                  </Form.Group>
                  {/* <label>Thumbnail</label> */}
                  {/* <img
                    // name="name"
                    // type="text"
                    // placeholder="Email"
                    // value={payload.email}
                    width="300"
                    height="300"
                    src={payload.thumbnailPath}
                    onChange={(e) =>
                      setpayload({ ...payload, user_email: e.target.value })
                    }
                  /> */}
                </Col>

                {/* <Col className="mb-3">
                  <label>Password</label>
                  <input
                    name="name"
                    type="password"
                    placeholder="password"
                    value={payload.password}
                    onChange={(e) =>
                      setpayload({ ...payload, user_password: e.target.value })
                    }
                  />
                </Col> */}
              </Row>
              {/* <Row>
                <Col className="mb-3">
                  <label>User Type</label>
                  <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                      {payload.user_type}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={(e) => {
                          setpayload({ ...payload, user_type: "salon" });
                        }}
                      >
                        Salon
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          setpayload({ ...payload, user_type: "stylist" });
                        }}
                      >
                        Stylist
                      </Dropdown.Item>{" "}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>

                <Col className="mb-3">
                  <label>Notification</label>
                  <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                      {payload.notification}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={(e) => {
                          setpayload({ ...payload, notification: "on" });
                        }}
                      >
                        On
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          setpayload({ ...payload, notification: "off" });
                        }}
                      >
                        Off
                      </Dropdown.Item>{" "}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
              <Row>
                <Col className="mb-3">
                  <label>User Verification status</label>
                  <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                      {payload.user_is_verified == 1
                        ? "Verified"
                        : "UnVerified"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={(e) => {
                          setpayload({ ...payload, user_is_verified: 0 });
                        }}
                      >
                        UnVerified
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          setpayload({ ...payload, user_is_verified: 1 });
                        }}
                      >
                        Verified
                      </Dropdown.Item>{" "}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>

                <Col className="mb-3">
                  <label>Job Type</label>
                  <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                      {payload.job_type}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={(e) => {
                          setpayload({ ...payload, job_type: "full_time" });
                        }}
                      >
                        Full Time
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          setpayload({ ...payload, notification: "part_time" });
                        }}
                      >
                        Part Time
                      </Dropdown.Item>{" "}
                      <Dropdown.Item
                        onClick={(e) => {
                          setpayload({ ...payload, notification: "contract" });
                        }}
                      >
                        Contract
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
              <Row>
                <Col className="mb-3">
                  <label>Address</label>
                  <input
                    name="name"
                    type="text"
                    placeholder="Address"
                    value={payload.address}
                    onChange={(e) =>
                      setpayload({ ...payload, address: e.target.value })
                    }
                  />
                </Col>

                <Col className="mb-3">
                  <label>Zip Postal Code</label>
                  <input
                    name="name"
                    type="text"
                    placeholder="zip postal code"
                    value={payload.zip_postal_code}
                    onChange={(e) =>
                      setpayload({
                        ...payload,
                        zip_postal_code: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row> */}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                setid("");
                reset();
                setshowncreatemodal(false);
                setshownupdatemodal(false);
              }}
            >
              Cancel
            </Button>
            {showncreatemodal ? (
              <Button
                variant="primary"
                type="submit"
                onClick={(e) => {
                  setshowncreatemodal(false);
                  setshownupdatemodal(false);
                  insertdata();
                  // createfa(e);
                }}
              >
                Create
              </Button>
            ) : (
              <Button
                variant="primary"
                type="submit"
                onClick={(e) => {
                  setshowncreatemodal(false);
                  setshownupdatemodal(false);
                  updatedata();
                  // updatefa(id, payload);
                }}
              >
                Update
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
