import React, { useState, useEffect } from "react";

import { Button, Col, Container, Row } from "react-bootstrap";
import Logo from "../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { adminLogin } from "../Services/Video.service";
import { toast } from "react-toastify";

export default function Login() {
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const navigate = useNavigate();
  const login = async () => {
    try {
      const response = await adminLogin({ email, password });

      if (response.status == 1) {
        // console.log(response);
        localStorage.setItem("isLogin", JSON.stringify(true));
        localStorage.setItem("user", JSON.stringify(response.data.Admin));
        localStorage.setItem("token", JSON.stringify(response.data.token));
        toast.success(response.message);
        setTimeout(() => {
          // navigate("/dashboard");
          window.location.reload();
        }, 1000);
      } else {
        toast.error(response.message);
      }
    } catch (e) {
      // console.log(e);
      toast.error(e.message);
    }
  };

  const islogin = JSON.parse(localStorage.getItem("isLogin"));

  useEffect(() => {
    if (islogin == true) {
      navigate("/dashboard");
    }
  }, [JSON.parse(localStorage.getItem("isLogin"))]);

  return (
    <Container>
      <Row className="">
        <Col xl="6" lg="8" className="mx-auto">
          <div className="loginform">
            {/* <img src={logo} alt="" /> */}

            <div className="bg-white p-4 w-100">
              <div className="text-center content-area">
                <div className="logo">
                  <img src={Logo} alt="Logo" />
                </div>
                <h2 className="main-heading mb-4">Login</h2>

                <div className="form-data">
                  <input
                    type="email"
                    className=""
                    placeholder="admin@mail.com"
                    value={email}
                    onChange={(e) => {
                      setemail(e.target.value);
                    }}
                  />
                </div>
                <div className="form-data">
                  {/* <div style={{ display: "flex" }}> */}
                  <input
                    type={passwordShown ? "text" : "password"}
                    className=""
                    placeholder="Password"
                    value={password}
                    onChange={(e) => {
                      setpassword(e.target.value);
                    }}
                  />
                  {/* <span
                      style={{
                        display: "flex",
                        textAlign: "center",
                        alignItems: "cente",
                      }}
                    >
                      show
                    </span> */}
                  {/* </div> */}
                </div>
                {/* <div className="d-flex justify-content-between w-100 align-items-center checkbox-div">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    Remember me
                  </label>
                </div>
                <a className="text-decoration-none forgot-text" href="#">
                  Forgot password?
                </a>
              </div> */}
                <div className="signin-btn w-100 mt-2">
                  <button
                    className="btn btn-primary btn-block"
                    onClick={() => {
                      login();
                    }}
                  >
                    Login
                  </button>
                </div>
                <div className="signin-btn w-100 mt-2">
                  <Button
                    // className="btn btn-primary btn-block"
                    variant="link"
                    onClick={() => {
                      // login();
                      navigate("/forgetpassword");
                    }}
                  >
                    forget password
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
