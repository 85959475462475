import { Action } from "../config/action";

export const SingleNotificationSend = async (payload) => {
  const data = await Action.post(`/notification/send`, payload);
  // console.log(data);
  return data.data;
};

export const NotificationtoAll = async (payload) => {
  const data = await Action.post(`/notification/send/all`, payload);

  return data.data;
};
export const getUsersId = async (payload) => {
  const data = await Action.get(`/getalluser`);
  // console.log(data.data);
  return data.data;
};
