import "./App.css";
import "./assets/css/fonts/style.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Grades from "./pages/Grades";
import Notification from "./pages/Notification";
import Subscription from "./pages/Subscription";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Error from "./pages/Error";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VideoCate from "./pages/videocat";
import Video from "./pages/video";
import ForgetPassword from "./pages/ForgetPassword";
import Chat from "./pages/Chat";
function App() {
  return (
    <>
      <Router>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/forgetpassword" element={<ForgetPassword />}></Route>
          <Route path="/*" element={<Error />}></Route>{" "}
          <Route path="/dashboard" element={<Dashboard />}></Route>
          {/* <Route path="/dashboard" element={<Notification/>}> </Route> */}
          <Route path="/chat" element={<Chat />}></Route>
          <Route path="/grades" element={<Grades />}></Route>
          <Route path="/video" element={<Video />}></Route>
          <Route path="/videocat" element={<VideoCate />}></Route>
          <Route path="/notification" element={<Notification />}></Route>
          <Route path="/subscription" element={<Subscription />}></Route>
          {/* <Route path="/users/:id" element={<Users />}></Route>           */}
        </Routes>
      </Router>
    </>
  );
}

export default App;
