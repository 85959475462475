import moment from "moment";
import React from "react";
import { imageURL } from "../../../config/config";
import Avatar from "../chatList/Avatar";

export default function ChatItem(props) {
  return (
    <div
      style={{ animationDelay: `0.8s` }}
      className={`chat__item ${props?.user ? props?.user : ""}`}
    >
      <div className="chat__item__content">
        <div className="chat__msg">{props?.msg}</div>
        <div className="chat__meta">
          {/* <span>Seen 1.03PM</span> */}
          <span></span>
          <span>{moment(props.time).format("LT")}</span>
          {/* <span>16 mins ago</span>
          <span>Seen 1.03PM</span> */}
        </div>
      </div>
      <Avatar
        isOnline="active"
        image={
          props?.image
            ? imageURL + props?.image
            : "https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png"
        }
      />
    </div>
  );
}
