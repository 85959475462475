import { Action } from "../config/action";

export const getSubscription = async () => {
  const data = await Action.get(`/subscrip/get`);

  return data.data.data;
};

export const deletesubscription = async (id) => {
  // console.log(id);
  const data = await Action.delete(`/subscrip/delete/${id}`);
  return data.data;
};
export const updatesubscription = async (
  id,
  payload,
  videoCategory,
  duration
) => {
  // delete payload.id;
  // delete payload.id;
  let newPayload = {
    name: payload.name,
    description: payload.description,
    price: payload.price,
  };
  if (videoCategory.length) newPayload.videoCategory = videoCategory;
  if (duration.length) newPayload.duration = duration;
  const data = await Action.put(`/subscrip/update/${id}`, newPayload);
  return data.data;
};
export const insertSubscriptions = async (payload, videoCategory, duration) => {
  const data = await Action.post(`/subscrip/add`, {
    ...payload,
    videoCategory,
    duration,
  });
  return data.data;
  // console.log(payload, newCatArr, newDurArr);
};
