import axios from "axios";
import { baseURL } from "./config";
export const Action = axios.create({
  baseURL: baseURL,

  headers: {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    // Accept: "application/json",
  },
});

export const ActionwithMultipart = axios.create({
  baseURL: baseURL,

  headers: {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    "Content-Type": "multipart/form-data",
    // Accept: "application/json",
  },
});
