import React, { useState, useEffect } from "react";

import { Button, Col, Container, Row } from "react-bootstrap";
import Logo from "../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { adminLogin } from "../Services/Video.service";
import { toast } from "react-toastify";
import {
  resetPaswordCall,
  sendOtpCall,
  verifyOtpCall,
} from "../Services/Auth.service";

export default function ForgetPassword() {
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [stage, setStage] = useState("forgetpassword");
  const [userId, serUserId] = useState("");
  const [otp, setOtp] = useState("");

  const navigate = useNavigate();
  const sendOtp = async () => {
    try {
      const response = await sendOtpCall({ email });
      // console.log(response);
      if (response.status == true) {
        // console.log(response);
        // localStorage.setItem("isLogin", JSON.stringify(true));
        // localStorage.setItem("user", JSON.stringify(response.data.Admin));
        // localStorage.setItem("token", JSON.stringify(response.data.token));
        serUserId(response.data._id);
        toast.success(response.message);
        setStage("verifyotp");
        // navigate("/dashboard");
      } else {
        toast.error(response.message);
      }
    } catch (e) {
      // console.log(e);
      toast.error(e.message);
    }
  };
  const checkOtp = async () => {
    try {
      const response = await verifyOtpCall({ otp, user_id: userId });
      if (response.status == true) {
        toast.success(response.message);
        setStage("confirmpassword");

        // navigate("/dashboard");
      } else {
        toast.error(response.message);
      }
    } catch (e) {
      // console.log(e);
      toast.error(e.message);
    }
  };
  const resetPassword = async () => {
    try {
      const response = await resetPaswordCall({
        confirmPassword,
        password,
        user_id: userId,
      });
      if (response.status == true) {
        toast.success(response.message);
        setStage("confirmpassword");
        // console.log({ response });
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      } else {
        toast.error(response.message);
      }
    } catch (e) {
      // console.log(e);
      toast.error(e.message);
    }
  };

  const islogin = JSON.parse(localStorage.getItem("isLogin"));

  useEffect(() => {
    if (islogin == true) {
      navigate("/dashboard");
    }
  }, [JSON.parse(localStorage.getItem("isLogin"))]);

  return (
    <Container>
      <Row className="">
        <Col xl="6" lg="8" className="mx-auto">
          <div className="loginform">
            <div className="bg-white p-4 w-100">
              <div className="text-center content-area">
                <div className="logo">
                  <img src={Logo} alt="Logo" />
                </div>
                <h2 className="main-heading mt-1 mb-4">
                  {stage == "forgetpassword"
                    ? "Forget Password"
                    : stage == "verifyotp"
                    ? "OTP"
                    : stage == "confirmpassword"
                    ? "New Password"
                    : ""}
                </h2>
                {stage == "forgetpassword" ? (
                  <>
                    <div className="form-data">
                      <input
                        type="email"
                        className=""
                        placeholder="admin@mail.com"
                        value={email}
                        onChange={(e) => {
                          setemail(e.target.value);
                        }}
                      />
                    </div>
                    <div className="signin-btn w-100 mt-2">
                      <button
                        className="btn btn-primary btn-block"
                        onClick={() => {
                          sendOtp();
                        }}
                      >
                        Send OTP
                      </button>
                    </div>
                    <div className="signin-btn w-100 mt-2">
                      <Button
                        // className="btn btn-primary btn-block"
                        variant="link"
                        onClick={() => {
                          // login();
                          navigate("/");
                        }}
                      >
                        Login
                      </Button>
                    </div>
                  </>
                ) : stage == "verifyotp" ? (
                  <>
                    <div className="form-data">
                      <input
                        type="text"
                        className=""
                        placeholder="123456"
                        value={otp}
                        onChange={(e) => {
                          setOtp(e.target.value);
                        }}
                      />
                    </div>
                    <div className="signin-btn w-100 mt-2">
                      <button
                        className="btn btn-primary btn-block"
                        onClick={() => {
                          checkOtp();
                        }}
                      >
                        Confirm OTP
                      </button>
                    </div>
                  </>
                ) : stage == "confirmpassword" ? (
                  <>
                    <div className="form-data">
                      <input
                        type="text"
                        className=""
                        placeholder="Enter new password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />
                      <input
                        type="text"
                        className=""
                        placeholder="Confirm new password"
                        value={confirmPassword}
                        onChange={(e) => {
                          setConfirmPassword(e.target.value);
                        }}
                      />
                    </div>
                    <div className="signin-btn w-100 mt-2">
                      <button
                        className="btn btn-primary btn-block"
                        onClick={() => {
                          resetPassword();
                        }}
                      >
                        Reset
                      </button>
                    </div>
                  </>
                ) : (
                  <>No Access to this page</>
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
