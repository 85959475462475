import { useEffect, useState } from "react";
import moment from "moment/moment";

import Sidebar from "../component/sidebar";
import Header from "../component/header";
import React from "react";
import "../component/chat/chatList/chatList.css";
import ChatListItems from "../component/chat/chatList/ChatListItems";

import { getgrades, addgrade, updategrade } from "../Services/Grades.service";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL, imageURL } from "../config/config";
import io from "socket.io-client";
import ChatContent from "../component/chat/chatContent/ChatContent";
import { Oval } from "react-loader-spinner";

export default function Chat() {
  const params = useParams();

  const [adminId, setAdminId] = useState("");
  const [roomId, setRoomId] = useState(null);
  const [allChats, setAllChats] = useState(null);
  const [chatLoading, setChatLoading] = useState(false);
  const [roomMessages, setRoomMessages] = useState(null);
  const socket = io(imageURL);
  const islogin = JSON.parse(localStorage.getItem("isLogin"));
  const navigate = useNavigate();

  useEffect(() => {
    if (islogin != true) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    socket.on("connect", function (idk) {
      console.log("connected id=" + socket.id, "userId=" + adminId);
    });

    setAdminId(JSON.parse(localStorage.getItem("user"))?._id);

    socket.on("disconnect", function (message) {
      console.log("Socket disconnected from server: ", message);
    });

    return () => {
      socket.close();
    };
    // eslint-disable-next-line
  }, [adminId]);

  useEffect(() => {
    if (adminId.length) {
      // send Id to server so all chats will send back to you
      socket.emit("getAllChatRoomsOfUser", {
        user: {
          id: adminId,
        },
      });

      // In this you will get all chats
      socket.on("getAllChatRoomsOfUser", function (newData) {
        console.log("allChats", newData);
        if (newData.length) setAllChats(newData);
        // setAllPost((prev) => [newData, ...prev]);
      });
    }

    return () => {
      socket.close();
    };
    // eslint-disable-next-line
  }, [adminId]);

  useEffect(() => {
    if (roomId) {
      // send room Id and user id to server to get all chats of room
      socket.emit("getRoomChats", {
        room: {
          id: roomId,
        },
        user: {
          id: adminId,
        },
      });

      // In this you will get chats of room
      socket.on("getRoomChats", function (newData) {
        console.log("allmessages", newData);
        setRoomMessages(newData);
        // if (newData.length) setAllChats(newData);
      });
    }

    return () => {
      socket.close();
    };
    // eslint-disable-next-line
  }, [roomId]);

  // const roomMessagesFunc = (messagesArr) => {
  //   setMessages(messagesArr);
  //   console.log(messagesArr);
  // };

  const setRoomIdFunc = (id) => {
    setRoomId(id);
    setChatLoading(true);
    console.log(id);
  };

  return (
    <>
      <Sidebar />
      <Header />
      <div className="wrapper">
        <div className="main__chatbody">
          {/* <ChatList /> */}
          <>
            <div className="main__chatlist">
              {/* <button className="btn">
          <i className="fa fa-plus"></i>
          <span>New conversation</span>
        </button> */}
              <div className="chatlist__heading">
                <h2>Chats</h2>
                <button className="btn-nobg">
                  <i className="fa fa-ellipsis-h"></i>
                </button>
              </div>
              {/* <div className="chatList__search">
          <div className="search_wrap">
            <input type="text" placeholder="Search Here" required />
            <button className="search-btn">
              <i className="fa fa-search"></i>
            </button>
          </div>
        </div> */}
              <div className="chatlist__items">
                {!allChats ? (
                  <div className="centerLoader">
                    <Oval
                      height={60}
                      width={60}
                      color="#c6d4ef"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#0a3481"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                      // style={{ justifyContent: "center" }}
                    />
                  </div>
                ) : (
                  allChats?.map((item, index) => {
                    return (
                      <ChatListItems
                        index={index}
                        setRoomIdFunc={setRoomIdFunc}
                        chatRoomId={item?.ChatRoom?._id}
                        lastUpdateTime={moment(
                          item?.ChatRoom?.updatedAt
                        ).format("LT")}
                        name={item?.User2?.fullName}
                        key={item._id}
                        animationDelay={index + 1}
                        active={item.active ? "active" : ""}
                        isOnline={item.isOnline ? "active" : ""}
                        image={
                          item?.User2?.profilePicture
                            ? imageURL + item?.User2?.profilePicture
                            : "https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png"
                        }
                      />
                    );
                  })
                )}
              </div>
            </div>
          </>
          <ChatContent
            roomMessages={roomMessages}
            adminId={adminId}
            roomId={roomId}
            socketInstance={socket}
            chatLoading={chatLoading}
          />
        </div>
      </div>
    </>
  );
}
