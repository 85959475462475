import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Modal,
  Dropdown,
  Card,
} from "react-bootstrap";
import Sidebar from "../component/sidebar";
import Header from "../component/header";

import { useNavigate, useResolvedPath } from "react-router-dom";

import CanvasJSReact from "./canvasjs.react";

import { LineWave } from "react-loader-spinner";
// import { getallusers } from "../Services/Video.service";
import { toast } from "react-toastify";
import { getSubscription } from "../Services/Subscription.service";
import ReactApexChart from "react-apexcharts";
import { getUserByMonthGraph } from "../Services/Graph.service";
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function Dashboard() {
  const [devicetype, setdevicetype] = useState([]);
  const [devicecount, setdevicecount] = useState([]);

  const [subs, setsubs] = useState([]);
  const [userObj, setUserObj] = useState({});
  const [userByMonthSeries, setUserByMonthSeries] = useState([
    {
      name: "Parents & Children",
      // data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2],
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ]);
  const [userByMonthOptions, setUserByMonthOptions] = useState({
    chart: {
      height: 350,
      type: "bar",
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        // return val + "%";
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },

    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      position: "top",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val;
        },
      },
    },
    title: {
      text: "Monthly User Graph " + new Date().getFullYear(),
      floating: true,
      offsetY: 330,
      align: "center",
      style: {
        color: "#444",
      },
    },
  });
  const [subsusers, setsubsusers] = useState([]);
  const [userbysubscription, setuserbysubscription] = useState([]);
  const [postbycategory, setpostbycategory] = useState([]);
  const [orderbyitem, setorderbyitem] = useState([]);

  const islogin = JSON.parse(localStorage.getItem("isLogin"));
  const navigate = useNavigate();
  const [loader, setloader] = useState(false);

  useEffect(() => {
    if (islogin != true) {
      navigate("/");
    }
    setloader(true);
    setUserObj(JSON.parse(localStorage.getItem("user")));

    const getdata = async () => {
      const newData = await getUserByMonthGraph();
      if (newData) {
        // console.log(newData.data);
        setUserByMonthSeries(
          userByMonthSeries.map((item) => {
            item.data = newData.data.map((item) => item.totalUser * 2);
            return item;
          })
        );
        // setUserByMonth((pre) =>
        // ({
        //   ...pre,
        //   series[0].data: data.data.map((item) => item.totalUser),
        // })
        // );
      }
      // const data = await getallusers();
      // if (data.status == 1) {
      //   var result = data.data.reduce(function (r, a) {
      //     r[a.user_device_type] = r[a.user_device_type] || [];
      //     r[a.user_device_type].push(a);
      //     return r;
      // }, Object.create(null));
      //  setdevicetype(Object.keys(result))
      //  setdevicecount(Object.values(result))
      // } else {
      //   toast.error(data.message);
      // }
      // setsubs( await getSubscription())
      // const subsuser= []
      // subs.map((item)=>{
      //   const count = data.filter(item=>item.subscription==item._id)
      //   subsuser.push({
      //     name:item.title,
      //     count:count.length
      //   })
      // })
      // setsubsusers(subsuser)
    };

    setTimeout(() => {
      setloader(true);
      getdata();
      setloader(false);
    }, 1000);
    getdata();
  }, []);

  /////
  ///

  const optionsbypostcategory = {
    title: {
      text: "Post By Category",
      fontSize: 20,
      margin: 10,
      fontFamily: "Roboto",
    },

    data: [
      {
        // Change type to "doughnut", "line", "splineArea", etc.
        type: "column",
        dataPoints: postbycategory,
      },
    ],
  };
  const optionsbyorderitem = {
    title: {
      text: "Order By Category",
      fontSize: 20,
      margin: 10,
      fontFamily: "Roboto",
    },
    data: [
      {
        // Change type to "doughnut", "line", "splineArea", etc.
        type: "column",
        dataPoints: orderbyitem,
      },
    ],
  };

  const optionsbylastseen = {
    title: {
      text: "Subscription by users",
      fontSize: 20,
      margin: 10,
      fontFamily: "Roboto",
    },
    data: [
      {
        // Change type to "doughnut", "line", "splineArea", etc.
        type: "column",
        dataPoints: [
          { label: "user1", y: 10 },
          { label: "user2", y: 15 },
          { label: "user3", y: 25 },
          { label: "user4", y: 30 },
          { label: "user5", y: 28 },
        ],
      },
    ],
  };
  const optionsforpie = {
    // exportEnabled: true,
    animationEnabled: true,
    title: {
      text: "User by subscription",
      fontSize: 20,
      margin: 10,
      fontFamily: "Roboto",
    },
    data: [
      {
        type: "pie",
        startAngle: 75,
        toolTipContent: "<b>{label}</b>: {y}%",
        showInLegend: "true",
        legendText: "{label}",
        indexLabelFontSize: 16,
        indexLabel: "{label} - {y}%",
        dataPoints: userbysubscription,
      },
    ],
  };

  return (
    <>
      <Sidebar />
      <Header />
      <div className="wrapper">
        {loader ? (
          <LineWave
            height="100"
            width="100"
            color="#4fa94d"
            ariaLabel="line-wave"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            firstLineColor=""
            middleLineColor=""
            lastLineColor=""
          />
        ) : (
          <Container fluid>
            <h1 className="mb-4">Hello {userObj?.name}</h1>

            <Row>
              {devicetype?.map((item, i) => {
                return (
                  <Col md={3}>
                    <div className="ratingbox">
                      <div className="d-flex justify-content-between align-items-center">
                        <h2>{devicecount[i].length}</h2>
                        <div className="icon">
                          <i className="icon-users"></i>
                        </div>
                      </div>
                      <p>{item == "null" ? "Testing Device" : item}</p>
                    </div>
                  </Col>
                );
              })}

              {/* <Col md={3}>
                <div className="ratingbox">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2>{order.length}</h2>
                    <div className="icon">
                      <i className="icon-shopping-cart"></i>
                    </div>
                  </div>
                  <p>Orders</p>
                </div>
              </Col>
              <Col md={3}>
                <div className="ratingbox">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2>{subs.length}</h2>
                    <div className="icon">
                      <i className="icon-thumbs-up"></i>
                    </div>
                  </div>
                  <p>Subscription</p>
                </div>
              </Col> */}
            </Row>
            <Row style={{ display: "flex" }}>
              <Col lg="6" className="mt-4">
                <Card>
                  <Card.Body>
                    <ReactApexChart
                      options={userByMonthOptions}
                      series={userByMonthSeries}
                      type="bar"
                      height={350}
                    />
                  </Card.Body>
                </Card>
              </Col>
              {/* <Col lg="6" className="mt-4">
                <Card>
                  <Card.Body>
                    <CanvasJSChart options={optionsbylastseen} />
                  </Card.Body>
                </Card>
              </Col> */}
              {/* <Col lg="6" className="mt-4">
                <Card>
                  <Card.Body>
                    <CanvasJSChart options={optionsbypostcategory} />
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="6" className="mt-4">
                <Card>
                  <Card.Body>
                    <CanvasJSChart options={optionsbyorderitem} />
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="6" className="mt-4">
                <Card>
                  <Card.Body>
                    <CanvasJSChart options={optionsforpie} />
                  </Card.Body>
                </Card>
              </Col> */}
            </Row>
          </Container>
        )}
      </div>
    </>
  );
}
