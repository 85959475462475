import React from "react";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Modal,
  Dropdown,
  Card,
  Table,
  Spinner,
  Form,
} from "react-bootstrap";
import edit from "../assets/images/Edit.png";
import deleteicon from "../assets/images/delete.png";
import Sidebar from "../component/sidebar";
import Header from "../component/header";
import {
  getNotification,
  getUsersId,
  insertNotification,
  Notificationsend,
  NotificationtoAll,
  SingleNotificationSend,
} from "../Services/Notification.service";
// import { getallusers } from "../Services/Video.service";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
export default function Notification() {
  // const [id, setid] = useState("");
  // const [showndeletemodal, setshowndeletemodal] = useState(false);
  // const [showncreatemodal, setshowncreatemodal] = useState(false);
  // const [shownupdatemodal, setshownupdatemodal] = useState(false);
  const [userIdsArr, setUserIdsArr] = useState([]);
  const [parentIdsArr, setParentIdsArr] = useState([]);
  const [ChildIdsArr, setChildIdsArr] = useState([]);
  const [allNotifiLoader, setAllNotifiLoader] = useState(false);
  const [singleNotifiLoader, setSingleNotifiLoader] = useState(false);
  const [handleRadioValue, setHandleRadioValue] = useState("parent");
  const [dropdownUserValue, setDropdownUserValue] = useState("");
  const [allUserpayload, setAllUserpayload] = useState({
    title: "",
    message: "",
  });
  const [payload, setpayload] = useState({
    title: "",
    message: "",
  });
  const [singleUserpayload, setSingleUserpayload] = useState({
    title: "",
    message: "",
    user_id: "",
  });
  const reset = () => {
    // setid("");
    setDropdownUserValue(null);
    setAllUserpayload({
      title: "",
      message: "",
    });
    setSingleUserpayload({
      title: "",
      message: "",
      user_id: "",
    });
    setpayload({
      title: "",
      message: "",
    });
  };
  // const [count, setcount] = useState([]);
  // const [page, setpage] = useState(1);
  // const updatedata = async () => {
  //   const arr = [...notification];
  //   arr[id] = payload;
  //   await insertNotification(arr);
  //   getdata();
  //   reset();
  // };
  // const deletedata = async () => {
  //   var arr = [...notification];
  //   arr.splice(id, 1);
  //   await insertNotification(arr);
  //   getdata();
  //   reset();
  // };

  const [userlist, setuserlist] = useState([]);

  const getdata = async () => {
    // const data = await getallusers();
    // if (data.status == 1) {
    //   setuserlist(data.data.map((item) => ({ ...item, checked: false })));
    // } else {
    //   toast.error(data.message);
    // }
  };

  const islogin = JSON.parse(localStorage.getItem("isLogin"));
  const navigate = useNavigate();
  useEffect(() => {
    if (islogin != true) {
      navigate("/");
    }
    getdata();
  }, []);
  useEffect(() => {
    getUserIdsFunc();
  }, []);
  const getUserIdsFunc = async () => {
    const data = await getUsersId();
    // console.log(data.data);
    setUserIdsArr(data.data.parents);
    setParentIdsArr(data.data.parents);
    setChildIdsArr(data.data.children);
  };

  const handleRadio = (e) => {
    // setHandleRadioValue(e.target.value);
    // console.log(e.target.value);
    e.target.value === "parent"
      ? setUserIdsArr(parentIdsArr)
      : setUserIdsArr(ChildIdsArr);
  };

  const sendnotification = async () => {
    // if (!allchecked) {
    //   var user_id = userlist.filter((item) => item.checked == true);
    //   user_id = user_id.map((i) => i._id);
    //   user_id.map(async (value) => {
    //     const res = await Notificationsend({
    //       title: payload.title,
    //       body: payload.body,
    //       user_id: value,
    //     });
    //     console.log(res);
    //     if (res.status == 0) {
    //       toast.error(res.message);
    //     } else {
    //       toast.success("Notification successfully send");
    //     }
    //   });
    //   setuserlist(userlist.map((item) => ({ ...item, checked: false })));
    //   // toast.success("Notification successfully send");
    //   reset();
    // } else {
    //   const data = await NotificationtoAll(payload);
    //   if (data.status == 1) {
    //     toast.success(data.message);
    //   } else {
    //     toast.error(data.message);
    //   }
    //   SelectAlluser();
    // }
    // // if (payload.user_id != "") {
    // //   const data = await Notification(payload);
    // //   if (data.status == 1) {
    // //     toast.success(data.message);
    // //   } else {
    // //     toast.error(data.message);
    // //   }
    // //   reset();
    // // } else {
    // //   toast.error("first Select user");
    // // }
  };
  const sendNotificationToAllUsers = async () => {
    setAllNotifiLoader(true);
    try {
      const data = await NotificationtoAll(allUserpayload);
      if (data.status == true) {
        toast.success(data.message);
        reset();
        setAllNotifiLoader(false);
      } else {
        setAllNotifiLoader(false);
        toast.error(data.message);
      }
    } catch (error) {
      setAllNotifiLoader(false);
      // console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };
  const sendNotificationToSingleUser = async () => {
    // console.log(singleUserpayload);
    setSingleNotifiLoader(true);

    try {
      const data = await SingleNotificationSend(singleUserpayload);
      // console.log({ data });
      if (data.status == true) {
        toast.success(data.message);
        reset();
        setSingleNotifiLoader(false);
      } else {
        setSingleNotifiLoader(false);
        toast.error(data.message);
      }
    } catch (error) {
      setSingleNotifiLoader(false);
      // console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };
  const [allchecked, setChecked] = useState(false);
  const handleCheck = (i) => {
    // var updatedList = [...checked];
    const updateusers = [...userlist];
    updateusers[i].checked = !updateusers[i].checked;
    setuserlist(updateusers);
    // if (checked.indexOf(id) != -1) {
    //   updatedList.splice(checked.indexOf(id), 1);
    // } else {
    //   updatedList = [...checked, id];
    // }

    // setChecked(updatedList);
  };
  const SelectAlluser = async () => {
    setChecked(!allchecked);

    const updateusers = [...userlist];

    setuserlist(updateusers.map((item) => ({ ...item, checked: !allchecked })));
  };
  return (
    <>
      <Sidebar />
      <Header />
      <div className="wrapper">
        <Container fluid>
          <h1>Notification</h1>
          <Card>
            <Card.Body>
              <Row>
                <div className="mb-3">
                  <h3>All Users</h3>
                </div>
                <Col>
                  <fieldset className="form-group">
                    <label htmlFor="basicInput">Title</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Title"
                      value={allUserpayload.title}
                      onChange={(e) => {
                        setAllUserpayload((pre) => {
                          return { ...pre, title: e.target.value };
                        });
                      }}
                    />
                  </fieldset>
                </Col>
                <Col>
                  <fieldset className="form-group">
                    <label htmlFor="basicInput">Message</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Message"
                      value={allUserpayload.message}
                      onChange={(e) => {
                        setAllUserpayload((pre) => {
                          return { ...pre, message: e.target.value };
                        });
                      }}
                    />
                  </fieldset>
                </Col>
                <Col className="align-self-center">
                  {allNotifiLoader ? (
                    <Button variant="primary">
                      <Spinner
                        as="span"
                        animation="border"
                        variant="light"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      variant="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        // console.log(allUserpayload);
                        sendNotificationToAllUsers();
                        // sendnotification();
                      }}
                    >
                      Submit
                    </Button>
                  )}
                </Col>
              </Row>
              <Row>
                <div className="my-3">
                  <h3>Single User</h3>
                </div>
                <Col>
                  <fieldset className="form-group">
                    <label htmlFor="basicInput">Title</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Title"
                      value={singleUserpayload.title}
                      onChange={(e) => {
                        setSingleUserpayload((pre) => {
                          return {
                            ...pre,
                            title: e.target.value,
                          };
                        });
                      }}
                    />
                  </fieldset>
                </Col>
                <Col>
                  <fieldset className="form-group">
                    <label htmlFor="basicInput">Message</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Message"
                      value={singleUserpayload.message}
                      onChange={(e) => {
                        setSingleUserpayload((pre) => {
                          return {
                            ...pre,
                            message: e.target.value,
                          };
                        });
                      }}
                    />
                  </fieldset>
                </Col>
                <Col>
                  <label htmlFor="basicInput">User Type</label>
                  {/* <label>Gender</label> */}
                  <div style={{ display: "flex" }}>
                    <input
                      type="radio"
                      style={{ minWidth: "50px" }}
                      name="gender"
                      value="parent"
                      onChange={handleRadio}
                      defaultChecked
                    />
                    Parent
                    <input
                      type="radio"
                      name="gender"
                      value="child"
                      style={{ minWidth: "50px" }}
                      onChange={handleRadio}
                    />
                    Child
                  </div>
                </Col>
                <Col
                // style={{
                //   overflow: "visible",
                // }}
                >
                  <label htmlFor="basicInput">Users</label>
                  <Dropdown
                  // flip={true}
                  >
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                      {dropdownUserValue}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      style={{
                        maxHeight: "150px",
                        overflowY: "scroll",
                      }}
                    >
                      {userIdsArr?.map((item, i) => (
                        <Dropdown.Item
                          key={i}
                          value={item.email}
                          onClick={(e) => {
                            setSingleUserpayload((pre) => {
                              return { ...pre, user_id: item._id };
                            });
                            setDropdownUserValue(item.email);
                          }}
                        >
                          {item.email} | {item.fullName ? item.fullName : ""}
                          {/* {console.log(item)} */}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col className="align-self-center">
                  {singleNotifiLoader ? (
                    <Button variant="primary">
                      <Spinner
                        as="span"
                        animation="border"
                        variant="light"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      variant="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        sendNotificationToSingleUser();
                        // sendnotification();
                        // console.log(singleUserpayload);
                      }}
                    >
                      Submit
                    </Button>
                  )}
                </Col>
              </Row>
              {/* <Row> */}
              {/* </Row> */}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  );
}
