import { Action } from "../config/action";

export const getgrades = async () => {
  const data = await Action.get("/grade/get");

  return data.data;
};

export const addgrade = async (payload) => {
  // console.log({ payload });
  const createddata = await Action.post("/grade/create", {
    name: payload.name,
  });
  return createddata.data;
};
export const updategrade = async (id, payload) => {
  // console.log(id, payload);
  const data = Object.fromEntries(
    Object.entries(payload).filter(([_, v]) => (v != null) & (v != ""))
  );

  const updateddata = await Action.put(`/grade/update/${id}`, data);
  return updateddata.data;
};
