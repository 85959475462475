import React from "react";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Modal,
  Dropdown,
  Card,
  Table,
  Form,
} from "react-bootstrap";
import edit from "../assets/images/Edit.png";
import deleteicon from "../assets/images/delete.png";
import Sidebar from "../component/sidebar";
import Header from "../component/header";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  deletesubscription,
  getSubscription,
  insertSubscriptions,
  updatesubscription,
} from "../Services/Subscription.service";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getvideoCategory } from "../Services/Video.service";
import { emptyLocalStorageOnLogout } from "../Services/Auth.service";

export default function Subscription() {
  const [id, setid] = useState("");
  const [showndeletemodal, setshowndeletemodal] = useState(false);
  const [showncreatemodal, setshowncreatemodal] = useState(false);
  const [shownupdatemodal, setshownupdatemodal] = useState(false);
  const [payload, setpayload] = useState({
    name: "",
    description: "",
    price: 0,
    // duration: [],
    // videoCategory: [],
  });
  const reset = () => {
    setid("");
    setSelectCat([]);
    setSelectDuration([]);
    setpayload({
      name: "",
      description: "",
      price: 0,
      // duration: [],
      // videoCategory: [],
    });
  };

  const [count, setcount] = useState([]);
  const [page, setpage] = useState(1);

  const [subscription, setsubscription] = useState([]);
  const [videoCategories, setVideoCategories] = useState([]);
  const [selectCat, setSelectCat] = useState([]);
  const [selectDuration, setSelectDuration] = useState([]);
  const [videoCategory, setVideoCategory] = useState("");
  const durationArr = [
    {
      label: "1",
      value: "1",
    },
    {
      label: "2",
      value: "2",
    },
    {
      label: "3",
      value: "3",
    },
    {
      label: "4",
      value: "4",
    },
    {
      label: "5",
      value: "5",
    },
    {
      label: "6",
      value: "6",
    },
    {
      label: "7",
      value: "7",
    },
    {
      label: "8",
      value: "8",
    },
    {
      label: "9",
      value: "9",
    },
    {
      label: "10",
      value: "10",
    },
    {
      label: "11",
      value: "11",
    },
    {
      label: "12",
      value: "12",
    },
  ];
  const getdata = async () => {
    try {
      const data = await getSubscription();
      setsubscription(data);
    } catch (error) {
      if (error.response.status == 401) {
        emptyLocalStorageOnLogout(navigate);
      }
    }
  };
  const getVideoCatFunc = async () => {
    const data = await getvideoCategory();
    // console.log(data);
    setVideoCategories(
      data.data?.map((item) => {
        return { label: item.name, value: item.id };
      })
    );
  };

  const animatedComponents = makeAnimated();
  const [filterdata, setfilterdata] = useState([]);

  const [filter, setfilter] = useState({
    attribute: "Select any",
    value: "",
  });
  useEffect(() => {
    // if (filter.attribute == "subId") {
    //   setfilterdata(
    //     subscription.filter((item) => {
    //       return item.subId.slice(-5) == filter.value;
    //     })
    //   );
    // } else {
    // }
    setfilterdata(
      subscription.filter((item) => {
        return item[filter.attribute] == filter.value;
      })
    );
  }, [filter.value]);

  useEffect(() => {
    if (filterdata.length == 0) {
      const N =
        subscription?.length % 10 == 0
          ? parseInt(subscription?.length / 10)
          : parseInt(subscription?.length / 10) + 1;
      setcount(Array.from({ length: N }, (_, index) => index + 1));
    } else {
      const N =
        filterdata?.length % 10 == 0
          ? parseInt(filterdata?.length / 10)
          : parseInt(filterdata?.length / 10) + 1;
      setcount(Array.from({ length: N }, (_, index) => index + 1));
    }
  }, [subscription]);

  const islogin = JSON.parse(localStorage.getItem("isLogin"));
  const navigate = useNavigate();
  useEffect(() => {
    if (islogin != true) {
      navigate("/");
    }
    setTimeout(() => {
      getdata();
      getVideoCatFunc();
    }, 1000);
    getdata();
  }, []);

  const updatedata = async () => {
    const newCat = selectCat.map((item) => item.value);
    const newDur = selectDuration.map((item) => ({ month: item.value }));
    // console.log({ payload, newCat, newDur, selectDuration, selectCat });
    // if (newDur.length != 3) {
    //   return toast.error("Three duration required");
    // }
    if (newDur.length < 1) {
      return toast.error("Duration is required");
    }
    if (newDur.length > 3) {
      return toast.error("Maximum 3 duration allowed ");
    }
    const value = await updatesubscription(id, payload, newCat, newDur);

    if (value.status == "1") {
      setshowncreatemodal(false);
      setshownupdatemodal(false);
      toast.success(value.message);
    } else {
      return toast.error(value.message);
    }

    setfilter({ ...filter, value: "" });

    getdata();
    reset();
  };
  const deletedata = async () => {
    const response = await deletesubscription(id);
    if (response.status == true) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
    getdata();
    setfilter({ ...filter, value: "" });
    reset();
  };
  const insertdata = async () => {
    const newCat = selectCat.map((item) => item.value);
    const newDur = selectDuration.map((item) => ({ month: item.value }));
    if (newDur.length < 1) {
      return toast.error("Duration is required");
    }
    if (newDur.length > 3) {
      return toast.error("Maximum 3 duration allowed ");
    }
    // console.log({ payload, newCat, newDur });
    const value = await insertSubscriptions(payload, newCat, newDur);
    // console.log(payload, newCat, newDur);
    if (value.status == "1") {
      toast.success(value.message);
      setshowncreatemodal(false);
      setshownupdatemodal(false);
    } else {
      return toast.error(value.message);
    }
    getdata();
    reset();
  };

  return (
    <>
      <Sidebar />
      <Header />
      <div className="wrapper">
        <Container fluid>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h1>Subscription</h1>
            <Button
              onClick={() => {
                setshowncreatemodal(true);
              }}
            >
              Add Subscription
            </Button>
          </div>
          <Card>
            <Card.Body>
              {/* <Row>
                <Col style={{ display: "flex" }}>
                  <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      {filter?.attribute}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={(e) => {
                          setfilter({ attribute: "title" });
                        }}
                      >
                        Title
                      </Dropdown.Item>{" "}
                      <Dropdown.Item
                        onClick={(e) => {
                          setfilter({ attribute: "weekly_cost" });
                        }}
                      >
                        Cost
                      </Dropdown.Item>{" "}
                    </Dropdown.Menu>
                  </Dropdown>
                  <input
                    style={{ marginLeft: "20px" }}
                    type="text"
                    placeholder={filter.attribute}
                    onChange={(e) => {
                      setfilter({ ...filter, value: e.target.value });
                    }}
                  ></input>
                </Col>
                <Col></Col>

                <Col></Col>
              </Row> */}

              <Table responsive>
                <thead className="thead">
                  <tr>
                    <td>Name</td>
                    <td>Description</td>
                    <td>Price</td>
                    <td>Duration</td>
                    <td>Video Category</td>
                    <td>Created Date</td>
                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                  {
                    // filterdata.length == 0
                    // ?.slice((page - 1) * 10, (page - 1) * 10 + 10)

                    subscription?.map((item, index) => {
                      return (
                        <tr>
                          <td>{item?.name}</td>
                          <td>{item?.description}</td>
                          <td>{item?.price}</td>
                          <td>
                            {item?.duration?.map((month) => (
                              // <span>{month.month}, </span>
                              <span>
                                <Button
                                  size="sm"
                                  disabled
                                  style={{ margin: 1 }}
                                >
                                  {month.month}
                                </Button>
                                &nbsp;
                                {/* &nbsp; */}
                              </span>
                            ))}
                          </td>
                          <td>
                            {item?.videoCategory?.map((video) => (
                              // <span>{video.video}, </span>
                              <span>
                                {/* <Button size="sm" disabled> */}
                                {video.name},{/* </Button> */}
                                &nbsp;
                                {/* &nbsp; */}
                              </span>
                            ))}
                          </td>
                          <td>{item?.createdAt}</td>

                          <td>
                            <div className="action">
                              <i
                                className="icon-edit"
                                onClick={() => {
                                  setid(item.id);
                                  setpayload(item);
                                  setSelectDuration(
                                    item?.duration?.map((item) => {
                                      return {
                                        label: item?.month?.toString(),
                                        value: item?.month?.toString(),
                                      };
                                    })
                                  );
                                  // console.log(
                                  //   item.duration.map((item) => {
                                  //     return {
                                  //       label: item.month,
                                  //       value: item.month,
                                  //     };
                                  //   })
                                  // );
                                  setSelectCat(
                                    item?.videoCategory?.map((item) => {
                                      return {
                                        label: item?.name?.toString(),
                                        value: item?.id?.toString(),
                                      };
                                    })
                                  );
                                  // console.log(
                                  //   item?.videoCategory?.map((item) => {
                                  //     return {
                                  //       label: item?.name?.toString(),
                                  //       value: item?.id?.toString(),
                                  //     };
                                  //   })
                                  // );
                                  setshownupdatemodal(true);
                                }}
                              ></i>

                              {/* <i
                                className="icon-trash"
                                onClick={() => {
                                  setid(item.id);

                                  setshowndeletemodal(true);
                                }}
                              ></i> */}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                    // : filterdata
                    //     ?.slice((page - 1) * 10, (page - 1) * 10 + 10)

                    //     ?.map((item, index) => {
                    //       return (
                    //         <tr>
                    //           <td>{item?.title}</td>
                    //           <td>{item?.weekly_cost}</td>

                    //           <td>
                    //             <div className="action">
                    //               <i
                    //                 className="icon-edit"
                    //                 onClick={() => {
                    //                   setid(item.id);
                    //                   setpayload(item);

                    //                   setshownupdatemodal(true);
                    //                 }}
                    //               ></i>

                    //               <i
                    //                 className="icon-trash"
                    //                 onClick={() => {
                    //                   setid(item.id);

                    //                   setshowndeletemodal(true);
                    //                 }}
                    //               ></i>
                    //             </div>
                    //           </td>
                    //         </tr>
                    //       );
                    //     })
                  }
                </tbody>
              </Table>

              {filterdata.length == 0 ? (
                <div className="menupagination">
                  Showing{" "}
                  {
                    subscription?.slice((page - 1) * 10, (page - 1) * 10 + 10)
                      ?.length
                  }
                  - {subscription?.length} results
                  <div className="pagination">
                    {page == 1 ? (
                      <button disable style={{ cursor: "default" }}>
                        Prev
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setpage(page - 1);
                        }}
                      >
                        Prev
                      </button>
                    )}

                    {count?.map((item, index) => {
                      return (
                        <button className={item == page ? "active" : ""}>
                          {item}
                        </button>
                      );
                    })}
                    {page == count?.length ? (
                      <button disable style={{ cursor: "default" }}>
                        Next
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setpage(page + 1);
                        }}
                      >
                        Next
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                <div className="menupagination">
                  Showing{" "}
                  {
                    filterdata?.slice((page - 1) * 10, (page - 1) * 10 + 10)
                      ?.length
                  }
                  - {filterdata?.length} results
                  <div className="pagination">
                    {page == 1 ? (
                      <button disable style={{ cursor: "default" }}>
                        Prev
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setpage(page - 1);
                        }}
                      >
                        Prev
                      </button>
                    )}

                    {count?.map((item, index) => {
                      return (
                        <button className={item == page ? "active" : ""}>
                          {item}
                        </button>
                      );
                    })}
                    {page == count?.length ? (
                      <button disable style={{ cursor: "default" }}>
                        Next
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setpage(page + 1);
                        }}
                      >
                        Next
                      </button>
                    )}
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>

          <Modal
            show={showndeletemodal}
            onHide={() => {
              reset();

              setshowndeletemodal(false);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Subscription</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex justify-content-center align-items-center">
                <Button
                  variant="danger"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    setid("");

                    setshowndeletemodal(false);
                  }}
                >
                  No
                </Button>
                <Button
                  variant="primary"
                  className="ml-2"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    deletedata();
                    setshowndeletemodal(false);
                  }}
                >
                  Yes
                </Button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            show={showncreatemodal || shownupdatemodal}
            onHide={() => {
              reset();

              setshowncreatemodal(false);
              setshownupdatemodal(false);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {showncreatemodal ? "Add" : "Update"} Package
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Row className="inputrow">
                  <Col className="mb-3">
                    <label>Name</label>
                    <input
                      name="name"
                      type="text"
                      placeholder="Enter Name"
                      value={payload.name}
                      onChange={(e) =>
                        setpayload({ ...payload, name: e.target.value })
                      }
                    />
                  </Col>

                  <Col className="mb-3">
                    <label>Description</label>
                    <input
                      name="name"
                      type="text"
                      placeholder="Enter Description"
                      value={payload.description}
                      onChange={(e) =>
                        setpayload({ ...payload, description: e.target.value })
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-3">
                    <label>Price</label>
                    <input
                      name="name"
                      type="number"
                      placeholder="Enter Price"
                      value={payload.price}
                      onChange={(e) =>
                        setpayload({ ...payload, price: e.target.value })
                      }
                    />
                  </Col>
                  <Col>
                    <label>Duration</label>
                    <div>
                      {/* <MultiSelect
                        style={{ margin: 0 }}
                        options={durationArr}
                        value={selectDuration}
                        onChange={setSelectDuration}
                        // onChange={(e) =>
                        //   setpayload((pre) => {
                        //     return { ...pre, videoCategory: e };
                        //   })
                        // }
                        labelledBy="Select"
                      /> */}
                      <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        onChange={setSelectDuration}
                        defaultValue={selectDuration}
                        isMulti
                        options={durationArr}
                      />
                    </div>
                    {/* <Dropdown>
                      <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        {videoCategory}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {videoCategories?.map((item) => (
                          <Dropdown.Item
                            value={item.name}
                            onClick={(e) => {
                              setpayload((pre) => {
                                return {
                                  ...pre,
                                  videoCategory: [
                                    ...pre.videoCategory,
                                    item.id,
                                  ],
                                };
                              });
                              setVideoCategory(item.name);
                            }}
                          >
                            {item.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown> */}
                  </Col>
                </Row>
                <Row className="inputrow">
                  <Col>
                    <label>Video Categories</label>
                    <div>
                      {/* <MultiSelect
                        options={videoCategories}
                        value={selectCat}
                        onChange={setSelectCat}
                        // onChange={(e) =>
                        //   setpayload((pre) => {
                        //     return { ...pre, videoCategory: e };
                        //   })
                        // }
                        labelledBy="Select"
                      /> */}
                      <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        onChange={setSelectCat}
                        defaultValue={selectCat}
                        isMulti
                        options={videoCategories}
                      />
                    </div>
                  </Col>
                </Row>

                {/* <Row>
                  <Col className="mb-3">
                    <label>Picture Upload</label>
                    <div className="custom-switch">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={payload.picture_upload}
                        onChange={(e) => {
                          setpayload({
                            ...payload,
                            picture_upload: !payload.picture_upload,
                          });
                        }}
                        label=""
                      />
                    </div>
                  </Col>

                  <Col className="mb-3">
                    <label>Job Filter</label>
                    <div className="custom-switch">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={payload.job_filter}
                        onChange={(e) => {
                          setpayload({
                            ...payload,
                            job_filter: !payload.job_filter,
                          });
                        }}
                        label=""
                      />
                    </div>
                  </Col>

                  <Col className="mb-3">
                    <label>Position Filter</label>
                    <div className="custom-switch">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={payload.position_filter}
                        onChange={(e) => {
                          setpayload({
                            ...payload,
                            position_filter: !payload.position_filter,
                          });
                        }}
                        label=""
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="inputrow">
                  <Col className="mb-3">
                    <label>Mile Radius Filter</label>
                    <div className="custom-switch">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={payload.mile_radius_filter}
                        onChange={(e) => {
                          setpayload({
                            ...payload,
                            mile_radius_filter: !payload.mile_radius_filter,
                          });
                        }}
                        label=""
                      />
                    </div>
                  </Col>

                  <Col className="mb-3">
                    <label>Pay Model Filter</label>
                    <div className="custom-switch">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={payload.pay_model_filter}
                        onChange={(e) => {
                          setpayload({
                            ...payload,
                            pay_model_filter: !payload.pay_model_filter,
                          });
                        }}
                        label=""
                      />
                    </div>
                  </Col>

                  <Col className="mb-3">
                    <label>Question Answer</label>
                    <div className="custom-switch">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={payload.question_answer}
                        onChange={(e) => {
                          setpayload({
                            ...payload,
                            question_answer: !payload.question_answer,
                          });
                        }}
                        label=""
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="inputrow">
                  <Col className="mb-3">
                    <label>Social</label>
                    <div className="custom-switch">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={payload.social}
                        onChange={(e) => {
                          setpayload({
                            ...payload,
                            social: !payload.social,
                          });
                        }}
                        label=""
                      />
                    </div>
                  </Col>

                  <Col className="mb-3">
                    <label>Social</label>
                    <div className="custom-switch">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={payload.swipping}
                        onChange={(e) => {
                          setpayload({
                            ...payload,
                            swipping: !payload.swipping,
                          });
                        }}
                        label=""
                      />
                    </div>
                  </Col>

                  <Col className="mb-3">
                    <label>Social</label>
                    <div className="custom-switch">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={payload.ads}
                        onChange={(e) => {
                          setpayload({
                            ...payload,
                            ads: !payload.ads,
                          });
                        }}
                        label=""
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="inputrow">
                  <Col className="mb-3">
                    <label>Invisible</label>
                    <div className="custom-switch">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={payload.invisible}
                        onChange={(e) => {
                          setpayload({
                            ...payload,
                            invisible: !payload.invisible,
                          });
                        }}
                        label=""
                      />
                    </div>
                  </Col>
                </Row> */}

                {/* <Row className="inputrow">
                  <Col>
                    <label>Spotlight per week</label>{" "}
                    <input
                      name="name"
                      type="Number"
                      placeholder="spotlight per week"
                      value={payload.spotlightperweek}
                      onChange={(e) =>
                        setpayload({
                          ...payload,
                          spotlightperweek: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row className="inputrow">
                  <Col>
                    <label>Backtract</label>
                    <div className="d-flex align-items-center">
                      <input
                        name="name"
                        type="checkbox"
                        label="Is_limit"
                        className="mr-2 check"
                        checked={payload.backtrack_islimit}
                        onChange={(e) =>
                          setpayload({
                            ...payload,
                            backtrack_islimit: !payload.backtrack_islimit,
                          })
                        }
                      />
                      {payload.backtrack_islimit ? (
                        <input
                          name="name"
                          type="Number"
                          placeholder="backtrack limit"
                          value={payload.backtrack_limit}
                          onChange={(e) =>
                            setpayload({
                              ...payload,
                              backtrack_limit: e.target.value,
                            })
                          }
                        />
                      ) : null}
                    </div>
                  </Col>

                  <Col>
                    <label>Application Limit</label>
                    <div className="d-flex align-items-center">
                      <input
                        name="name"
                        type="checkbox"
                        label="Is_limit"
                        className="mr-2 check"
                        checked={payload.application_islimit}
                        onChange={(e) =>
                          setpayload({
                            ...payload,
                            application_islimit: !payload.application_islimit,
                          })
                        }
                      />
                      {payload.application_islimit ? (
                        <input
                          name="name"
                          type="Number"
                          placeholder="applicaion limit"
                          value={payload.application_limit}
                          onChange={(e) => {
                            setpayload({
                              ...payload,
                              application_limit: e.target.value,
                            });
                          }}
                        />
                      ) : null}
                    </div>
                  </Col>
                </Row> */}
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  setid("");
                  reset();
                  setshowncreatemodal(false);
                  setshownupdatemodal(false);
                }}
              >
                Cancel
              </Button>
              {showncreatemodal ? (
                <Button
                  variant="primary"
                  type="submit"
                  onClick={(e) => {
                    // setshowncreatemodal(false);
                    // setshownupdatemodal(false);
                    insertdata();
                    // createfa(e);
                  }}
                >
                  Create
                </Button>
              ) : (
                <Button
                  variant="primary"
                  type="submit"
                  onClick={(e) => {
                    // setshowncreatemodal(false);
                    // setshownupdatemodal(false);
                    updatedata();
                    // updatefa(id, payload);
                  }}
                >
                  Update
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
    </>
  );
}
