import React from "react";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Dropdown,
  Modal,
  Table,
  Card,
  Form,
  Toast,
  Ratio,
  Spinner,
} from "react-bootstrap";
import edit from "../assets/images/Edit.png";
import deleteicon from "../assets/images/delete.png";
import Sidebar from "../component/sidebar";
import Header from "../component/header";

import {
  getvideos,
  addvideo,
  updatevideo,
  getvideosById,
  getvideoCategory,
} from "../Services/Video.service";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";
import { imageURL } from "../config/config";
import { Action } from "../config/action";
import { getgrades } from "../Services/Grades.service";
import { emptyLocalStorageOnLogout } from "../Services/Auth.service";

export default function Video() {
  const params = useParams();

  const [id, setid] = useState("");
  const [showndeletemodal, setshowndeletemodal] = useState(false);
  const [showncreatemodal, setshowncreatemodal] = useState(false);
  const [shownupdatemodal, setshownupdatemodal] = useState(false);
  const [viewDetailsModal, setViewDetailsModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [videoDetailsPayload, setVideoDetailsPayload] = useState({});

  const [videoCategories, setVideoCategories] = useState([]);
  const [grades, setGrades] = useState([]);
  const [image, setimage] = useState({});
  const [thumbnail, setThumbnail] = useState(null);
  const [uploadVideo, setUploadVideo] = useState(null);
  const getCatId = (item) => {
    const object = videoCategories?.find(
      (obj) => obj.name === item.category
      // ? obj.id : item.category
    );
    // console.log(object?.id);
    return object?.id;
  };
  const getGradeId = (item) => {
    const object = grades?.find(
      (obj) => obj.name === item.grade
      // ? obj.id : item.category
    );
    // console.log(object?.id);
    return object?.id;
  };
  const reset = () => {
    setid("");
    setpayload({
      title: "",
      description: "",
      grade: "",
      category: "",
    });
    setVideoCategory("");
    setSelectGrade("");
  };

  const [count, setcount] = useState([]);
  const [page, setpage] = useState(1);
  const [limit, setLimit] = useState(20);

  const [video, setvideo] = useState([]);
  const [videoCategory, setVideoCategory] = useState(null);
  const [selectGrade, setSelectGrade] = useState(null);
  // const [videoCategoryId, setVideoCategoryId] = useState(null);
  const [videocount, setvideocount] = useState(0);

  const [payload, setpayload] = useState({
    title: "",
    description: "",
    grade: "",
    category: "",
    // video_url: "",
    // thumbnail_url: "",
    // duration: "",
  });
  const [videoFile, setVideoFile] = useState(null);
  const [thumbnailFile, setThumbnailFile] = useState(null);

  const getdata = async () => {
    try {
      const data = await getvideos();
      console.log({ data });

      if (data.status == true) {
        const N =
          data?.data.length % limit == 0
            ? parseInt(data?.data.length / limit)
            : parseInt(data?.data.length / limit) + 1;

        setcount(Array.from({ length: N }, (_, index) => index + 1));
        setvideocount(data.data.length);
        setvideo(data.data);
      } else {
        toast.error(data.message);
      }
      // console.log(video);
    } catch (error) {
      if (error.response.status == 401) {
        emptyLocalStorageOnLogout(navigate);
      }
    }
  };
  // useEffect(() => {

  // }, [page]);
  // const getdatabyquery = async (query) => {
  //   const data = await getuserbyquery(query);

  //   if (data.status == 1) {
  //     const N =
  //       data?.count % 10 == 0
  //         ? parseInt(data?.count / 10)
  //         : parseInt(data?.count / 10) + 1;

  //     setcount(Array.from({ length: N }, (_, index) => index + 1));
  //     setusercount(data.count);
  //     setuser(data.data);
  //   } else {
  //     toast.error(data.message);
  //   }
  // };

  const updatedata = async () => {
    setShowLoading(true);
    const response = await updatevideo(id, payload, videoFile, thumbnailFile);
    setpage(1);

    if (response.status == true) {
      toast.success(response.message);
      setShowLoading(false);
      setshowncreatemodal(false);
      setshownupdatemodal(false);
    } else {
      toast.error(response.message);
      setShowLoading(false);
      setshowncreatemodal(false);
      setshownupdatemodal(false);
    }
    getdata();
    reset();
  };
  // const deletedata = async () => {
  //   const response = await deleteUser(id);
  //   if (response.status == 1) {
  //     toast.success(response.message);
  //   } else {
  //     toast.error(response.message);
  //   }

  //   getdata();
  //   reset();
  // };
  const insertdata = async () => {
    // // console.log(payload);
    // console.log(payload);
    // console.log(videoFile);
    // console.log(videoFile.type);
    const regexMp4 = /.mp4/gi;
    if (!regexMp4.test(videoFile.type)) {
      // console.log(true);
      return toast.error("Only mp4 files are allowed");
    }
    setShowLoading(true);
    try {
      const data = await addvideo(payload, videoFile, thumbnailFile);
      setpage(1);
      console.log({ data });
      if (data.status == true) {
        toast.success(data.message);
        getdata(page);
        setShowLoading(false);
        setshowncreatemodal(false);
        setshownupdatemodal(false);
      } else {
        toast.error(data.message);
        setShowLoading(false);
        setshowncreatemodal(false);
        setshownupdatemodal(false);
      }
    } catch (error) {
      console.log({ error });

      toast.error(error);
      setShowLoading(false);
      setshowncreatemodal(false);
      setshownupdatemodal(false);
    }
    reset();
  };

  const [filterdata, setfilterdata] = useState([]);

  const [filter, setfilter] = useState({
    attribute: "Select any",
    value: "",
  });

  const getVideoCategories = async () => {
    const data = await getvideoCategory();
    // console.log({ data });
    setVideoCategories(data.data);
  };
  const getGrades = async () => {
    const data = await getgrades();
    // console.log({ data });
    setGrades(data.data);
  };
  useEffect(() => {
    const values = Object.values(filter);
    if (values[1] != "") {
      setfilterdata(
        video.filter((item) => {
          return item[filter.attribute] == filter.value;
        })
      );
    } else {
      setfilterdata([]);
    }
  }, [filter.value]);

  useEffect(() => {
    if (filterdata.length == 0) {
      const N =
        video?.length % limit == 0
          ? parseInt(video?.length / limit)
          : parseInt(video?.length / limit) + 1;
      setcount(Array.from({ length: N }, (_, index) => index + 1));
    } else {
      const N =
        filterdata?.length % limit == 0
          ? parseInt(filterdata?.length / limit)
          : parseInt(filterdata?.length / limit) + 1;
      setcount(Array.from({ length: N }, (_, index) => index + 1));
    }
  }, [filterdata]);

  const islogin = JSON.parse(localStorage.getItem("isLogin"));
  const navigate = useNavigate();
  useEffect(() => {
    // setShowLoading(false);
    // console.log("chal na bhai");
    // setTimeout(() => {
    //   setShowLoading(false);
    // }, 1000);
    if (islogin != true) {
      navigate("/");
    }
    getVideoCategories();
    getGrades();
    getdata();
  }, []);

  useEffect(() => {
    setShowLoading(true);
    // console.log("ok");
    setTimeout(() => {
      setShowLoading(false);
    }, 700);
  }, [page]);

  return (
    <>
      <Sidebar />
      <Header />
      <div className="wrapper">
        <Container fluid>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h1>Videos</h1>
            <Button
              className="mb-2"
              onClick={() => {
                setshowncreatemodal(true);
              }}
            >
              Add Video
            </Button>
          </div>
          <Card>
            <Card.Body>
              <Row>
                {/* <Col style={{ display: "flex" }}>
                  <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      {filter?.attribute}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={(e) => {
                          setfilter({ ...filter, attribute: "first_name" });
                        }}
                      >
                        Full Name
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          setfilter({ ...filter, attribute: "user_email" });
                        }}
                      >
                        Email
                      </Dropdown.Item>{" "}
                      <Dropdown.Item
                        onClick={(e) => {
                          setfilter({
                            ...filter,
                            attribute: "user_is_verified",
                          });
                        }}
                      >
                        Is Verified
                      </Dropdown.Item>{" "}
                    </Dropdown.Menu>
                  </Dropdown>
                  {filter.attribute == "user_is_verified" ? (
                    <Dropdown>
                      <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        user is verified
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          value={0}
                          onClick={(e) => {
                            setfilter({ ...filter, value: 0 });
                          }}
                        >
                          UnVerified
                        </Dropdown.Item>
                        <Dropdown.Item
                          value={1}
                          onClick={(e) => {
                            setfilter({ ...filter, value: 1 });
                          }}
                        >
                          Verified
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <input
                      className="ml-3"
                      type="text"
                      placeholder={filter.attribute}
                      onChange={(e) => {
                        setfilter({ ...filter, value: e.target.value });
                      }}
                    ></input>
                  )}
                </Col> */}
              </Row>

              <Table className="" responsive>
                <thead className="thead">
                  <tr>
                    <td>Thumbnail</td>
                    <td>Title </td>
                    <td>Description</td>
                    {/* <td>City Name</td> */}
                    <td>Grade</td>
                    <td>Category</td>
                    {/* <td>Video</td> */}
                    <td>Duration</td>
                    <td>Created Date</td>
                    <td>Details</td>
                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                  {!video?.length || showLoading === true ? (
                    <span className="loading-table">loading...</span>
                  ) : (
                    video
                      ?.slice(limit * page - limit, limit * page)
                      .map((item, index) => {
                        return (
                          <tr>
                            <td>
                              <img
                                src={item?.thumbnail_url}
                                width="100"
                                height="100"
                                // title="thumbnail"
                              />
                            </td>
                            <td>
                              {item.title}
                              {/* // ? item.full_name // : item?.first_name +
                          item?.last_name} */}
                            </td>
                            <td>
                              <p>{item?.description}</p>
                            </td>
                            <td>{item?.grade}</td>
                            <td>{item?.category}</td>
                            {/* <td>
                          <video controls width="100" height="100">
                            <source type="video/mp4" src={item?.video_url} />
                          </video>
                        </td> */}
                            <td>{item?.duration}</td>
                            <td>
                              {/* {new Date(item?.createdAt).toLocaleTimeString()} */}
                              {item?.createdAt}
                            </td>
                            <td>
                              {/* {new Date(item?.createdAt).toLocaleTimeString()} */}
                              <Button
                                variant="primary"
                                size="sm"
                                type="submit"
                                onClick={(e) => {
                                  setViewDetailsModal(true);
                                  setVideoDetailsPayload(item);
                                  // setshownupdatemodal(false);
                                  // insertdata();
                                  // createfa(e);
                                }}
                              >
                                View
                              </Button>
                            </td>
                            {/* <td>
                          <p>{item?.titles?.join(", ")}</p>
                        </td>
                        <td>
                          {item?.user_is_verified == 1
                            ? "Verified"
                            : "UnVerified"}
                        </td>
                        <td>{item?.spotlightperweek}</td> */}

                            <td>
                              <div className="action">
                                <i
                                  className="icon-edit"
                                  onClick={() => {
                                    setid(item.id);
                                    setpayload({
                                      title: item.title,
                                      description: item.description,
                                      category: getCatId(item),
                                      grade: getGradeId(item),
                                    });
                                    setVideoCategory(item.category);
                                    // const findCatId = videoCategories.find((obj) =>
                                    //   obj.name === item.category
                                    //     ? obj.id
                                    //     : item.category
                                    // );
                                    setSelectGrade(item.grade);
                                    setshownupdatemodal(true);
                                  }}
                                ></i>

                                {/* <i
                              className="icon-trash"
                              onClick={() => {
                                setid(item._id);

                                setshowndeletemodal(true);
                              }}
                            ></i> */}
                              </div>
                            </td>
                          </tr>
                        );
                      })
                  )}
                </tbody>
              </Table>
              <div className="menupagination">
                Showing{" "}
                {limit * page > video.length ? video.length : limit * page}-{" "}
                {video.length} results
                <div className="pagination">
                  {page == 1 ? (
                    <button disable style={{ cursor: "default" }}>
                      Prev
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setpage(page - 1);
                      }}
                    >
                      Prev
                    </button>
                  )}

                  {count?.map((item, index) => {
                    return (
                      <button
                        className={item == page ? "active" : ""}
                        onClick={() => {
                          setpage(item);
                        }}
                      >
                        {item}
                      </button>
                    );
                  })}
                  {page == count?.length ? (
                    <button disable style={{ cursor: "default" }}>
                      Next
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setpage(page + 1);
                      }}
                    >
                      Next
                    </button>
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Container>
        {/* delete modal
        <Modal
          show={showndeletemodal}
          onHide={() => {
            reset();

            setshowndeletemodal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Video</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center align-items-center">
              <Button
                variant="secondary"
                type="submit"
                className="mr-2"
                onClick={(e) => {
                  e.preventDefault();
                  setid("");

                  setshowndeletemodal(false);
                }}
              >
                No
              </Button>
              <Button
                variant="primary"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  deletedata();
                  setshowndeletemodal(false);
                }}
              >
                Yes
              </Button>
            </div>
          </Modal.Body>
        </Modal> */}

        <Modal
          show={showncreatemodal || shownupdatemodal}
          size="lg"
          onHide={() => {
            reset();

            setshowncreatemodal(false);
            setshownupdatemodal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {showncreatemodal ? "Add" : "Update"} Video
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col className="mb-3">
                  {" "}
                  <label>Title</label>{" "}
                  <input
                    name="name"
                    type="text"
                    placeholder="title"
                    value={payload.title}
                    onChange={(e) =>
                      setpayload({ ...payload, title: e.target.value })
                    }
                  />
                </Col>
                {/* <Col className="mb-3">
                  <label>Duration</label>
                  <input
                    name="name"
                    type="number"
                    placeholder="duration"
                    value={payload.duration}
                    onChange={(e) =>
                      setpayload({ ...payload, duration: e.target.value })
                    }
                  />
                </Col> */}
                {/* </Row> */}

                {/* <Row> */}
                <Col className="mb-3">
                  <label>Description</label>
                  <input
                    name="name"
                    type="text"
                    placeholder="description"
                    value={payload.description}
                    onChange={(e) =>
                      setpayload({ ...payload, description: e.target.value })
                    }
                  />
                </Col>

                {/* <Col className="mb-3">
                  <label>Password</label>
                  <input
                    name="name"
                    type="password"
                    placeholder="password"
                    value={payload.password}
                    onChange={(e) =>
                      setpayload({ ...payload, user_password: e.target.value })
                    }
                  />
                </Col> */}
              </Row>
              <Row>
                <Col className="mb-3">
                  <Form.Group
                    //  controlId="formFile"
                    className="mb-3"
                  >
                    <Form.Label>Video</Form.Label>
                    <Form.Control
                      type="file"
                      onChange={(e) => {
                        setVideoFile(e.target.files[0]);
                        // setpayload((pre) => {
                        //   return { ...pre, video_url: e.target.files[0] };
                        // });
                      }}
                      //  onChange={()=>}
                      // onChange={(e) => setUploadVideo(e.target.files[0])}
                      accept="video/*"
                      name="fileInput"
                      id="fileInput"
                    />
                  </Form.Group>
                </Col>

                <Col className="mb-3">
                  <label>Video Category</label>
                  <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                      {videoCategory}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {videoCategories?.map((item) => (
                        <Dropdown.Item
                          value={item.name}
                          onClick={(e) => {
                            // // console.log(e.target.value);
                            // // console.log(e.target);
                            // // console.log(item.id);
                            // setVideoCategoryId(item.id);
                            setpayload((pre) => {
                              return { ...pre, category: item.id };
                            });
                            setVideoCategory(item.name);
                          }}
                        >
                          {item.name}
                        </Dropdown.Item>
                      ))}
                      {/* <Dropdown.Item
                        onClick={(e) => {
                          setpayload({ ...payload, user_type: "salon" });
                        }}
                      >
                        Salon
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          setpayload({ ...payload, user_type: "stylist" });
                        }}
                      >
                        Stylist
                      </Dropdown.Item>{" "} */}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
              <Row>
                <Col className="mb-3">
                  <Form.Group
                    //  controlId="formFile"
                    className="mb-3"
                  >
                    <Form.Label>Thumbnail</Form.Label>
                    <Form.Control
                      type="file"
                      //  onChange={()=>}
                      onChange={(e) => {
                        setThumbnailFile(e.target.files[0]);
                        // setpayload((pre) => {
                        //   return { ...pre, thumbnail_url: e.target.files[0] };
                        // });
                      }}
                      accept="image/*"
                      name="fileInput"
                      id="fileInput"
                    />
                  </Form.Group>
                </Col>
                <Col className="mb-3">
                  <label>Grade</label>
                  <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                      {/* {payload.grade} */}
                      {selectGrade}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {grades?.map((item) => (
                        <Dropdown.Item
                          onClick={(e) => {
                            // setVideoCategoryId(item.id);
                            setpayload((pre) => {
                              return { ...pre, grade: item.id };
                            });
                            setSelectGrade(item.name);
                          }}
                        >
                          {item.name}
                        </Dropdown.Item>
                      ))}

                      {/* <Dropdown.Item
                        onClick={(e) => {
                          setpayload({ ...payload, notification: "on" });
                        }}
                      >
                        On
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          setpayload({ ...payload, notification: "off" });
                        }}
                      >
                        Off
                      </Dropdown.Item>{" "} */}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
              {/* <Row>
                <Col className="mb-3">
                  <label>User Verification status</label>
                  <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                      {payload.user_is_verified == 1
                        ? "Verified"
                        : "UnVerified"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={(e) => {
                          setpayload({ ...payload, user_is_verified: 0 });
                        }}
                      >
                        UnVerified
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          setpayload({ ...payload, user_is_verified: 1 });
                        }}
                      >
                        Verified
                      </Dropdown.Item>{" "}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>

                <Col className="mb-3">
                  <label>Job Type</label>
                  <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                      {payload.job_type}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={(e) => {
                          setpayload({ ...payload, job_type: "full_time" });
                        }}
                      >
                        Full Time
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          setpayload({ ...payload, notification: "part_time" });
                        }}
                      >
                        Part Time
                      </Dropdown.Item>{" "}
                      <Dropdown.Item
                        onClick={(e) => {
                          setpayload({ ...payload, notification: "contract" });
                        }}
                      >
                        Contract
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
              <Row>
                <Col className="mb-3">
                  <label>Address</label>
                  <input
                    name="name"
                    type="text"
                    placeholder="Address"
                    value={payload.address}
                    onChange={(e) =>
                      setpayload({ ...payload, address: e.target.value })
                    }
                  />
                </Col>

                <Col className="mb-3">
                  <label>Zip Postal Code</label>
                  <input
                    name="name"
                    type="text"
                    placeholder="zip postal code"
                    value={payload.zip_postal_code}
                    onChange={(e) =>
                      setpayload({
                        ...payload,
                        zip_postal_code: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row> */}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                setid("");
                reset();
                setshowncreatemodal(false);
                setshownupdatemodal(false);
              }}
            >
              Cancel
            </Button>
            {showncreatemodal ? (
              <>
                {showLoading ? (
                  <Button variant="primary">
                    <Spinner
                      as="span"
                      animation="border"
                      variant="light"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={(e) => {
                      insertdata();
                      // createfa(e);
                    }}
                  >
                    Create
                  </Button>
                )}
              </>
            ) : (
              <>
                {showLoading ? (
                  <Button variant="primary">
                    <Spinner
                      as="span"
                      animation="border"
                      variant="light"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={(e) => {
                      updatedata();
                      // updatefa(id, payload);
                    }}
                  >
                    Update
                  </Button>
                )}
              </>
            )}
          </Modal.Footer>
        </Modal>
        <Modal
          show={viewDetailsModal}
          size="lg"
          onHide={() => {
            reset();
            setViewDetailsModal(false);
            // setshowncreatemodal(false);
            // setshownupdatemodal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Video Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col className="mb-3">
                  <label>Title</label>
                  <input
                    name="name"
                    type="text"
                    disabled
                    value={videoDetailsPayload?.title}
                  />
                </Col>
                <Col className="mb-3">
                  <label>Description</label>
                  <input
                    name="name"
                    type="text"
                    disabled
                    value={videoDetailsPayload?.description}
                  />
                </Col>
              </Row>
              <Row>
                {/* <Col></Col> */}
                <Col className="mb-3">
                  <label>Video</label>
                  <video controls className="w-100" height="300">
                    <source
                      type="video/mp4"
                      src={videoDetailsPayload?.video_url}
                    />
                  </video>
                </Col>
                {/* <Col></Col> */}
              </Row>
              <Row>
                <Col className="mb-3">
                  <label>Video Category</label>
                  <input
                    name="name"
                    type="text"
                    disabled
                    value={videoDetailsPayload?.category}
                  />
                </Col>
                <Col className="mb-3">
                  <label>Grade</label>
                  <input
                    name="name"
                    type="text"
                    disabled
                    value={videoDetailsPayload?.grade}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mb-3">
                  <label>Thumbnail</label>
                  <img
                    src={videoDetailsPayload?.thumbnail_url}
                    // width="500"
                    className="w-100"
                    // height="300"
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mb-3">
                  <label>Duration</label>
                  <input
                    name="name"
                    type="text"
                    disabled
                    value={videoDetailsPayload?.duration}
                  />
                </Col>
                <Col className="mb-3">
                  <label>Created Date</label>
                  <input
                    name="name"
                    type="text"
                    disabled
                    value={videoDetailsPayload?.createdAt}
                  />
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                reset();
                setViewDetailsModal(false);
              }}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
