import React, { Component, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../assets/images/logo.png";
import Admin from "../assets/images/team.png";
import Toggle from "../assets/images/toggle.png";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import { useLocation, useNavigate } from "react-router-dom";
function BasicExample() {
  const location = useLocation();
  const navigate = useNavigate();
  const path = window.location.pathname.split("/")[1].toLowerCase();
  const handleClick = () => {
    // 👇️ toggle class on the body element
    document.body.classList.toggle("hide-sidebar");
  };
  const [userObj, setUserObj] = useState({});

  useEffect(() => {
    setUserObj(JSON.parse(localStorage.getItem("user")));
  }, []);
  return (
    <div className="custom-navigation">
      <Container fluid>
        <Row>
          <Col xs="4" className="d-flex align-items-center">
            <div className="nav-toogle " onClick={handleClick}>
              <img src={Toggle} alt="Logo" />
            </div>
            <div className="logo">
              <img src={Logo} alt="Logo" />
            </div>
          </Col>

          <Col xs="8" className="d-flex align-items-center justify-content-end">
            <Nav>
              <Dropdown>
                <Dropdown.Toggle variant="" id="dropdown-basic">
                  <img src={Admin} alt="Logo" />
                  {userObj?.name}
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  <Dropdown.Item
                    onClick={() => {
                      // localStorage.setItem("isLogin", JSON.stringify(false));
                      // localStorage.setItem("user", "");
                      // localStorage.setItem("token", "");
                      localStorage.removeItem("isLogin");
                      localStorage.removeItem("user");
                      localStorage.removeItem("token");

                      navigate("/");
                    }}
                  >
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default BasicExample;
